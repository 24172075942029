import { Accordion } from '@chakra-ui/react';
import React from 'react';

export const SettingsAccordion = (props: React.PropsWithChildren) => {
  return (
    <Accordion allowMultiple defaultIndex={0}>
      {props.children}
    </Accordion>
  );
};
