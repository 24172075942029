import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { FileUploadControl, FileUploadItemsProgress, useFileUpload } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { BulletSteps } from '../../components/generic/BulletSteps';
import { useAppContext } from '../../hooks/useAppContext';
import { useAxios } from '../../hooks/useAxios';

interface Props {
  onBack: () => void;
  onClose: () => void;
}

export const UploadActionStep = (props: Props) => {
  const { onClose, onBack } = props;
  const { config, organization, auth } = useAppContext();
  const axios = useAxios();

  const sendFile = React.useCallback(
    async (
      file: File,
      onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
    ) => {
      const signedUrlResp = await axios({
        url: `${config.api.url}/organizations/${organization.id}/upload-instance`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { filename: file.name },
      });
      const { signedUrl, blobUri } = await signedUrlResp.data;

      await axios({
        url: signedUrl,
        method: 'PUT',
        headers: { 'Content-Type': 'application/octet-stream' },
        data: await file.arrayBuffer(),
        onUploadProgress: (event) => {
          if (!event.total) {
            return;
          }

          onProgress({
            percentage: (event.loaded / event.total) * 100,
            total: event.total,
            loaded: event.loaded,
          });
        },
      });

      await axios({
        url: `${config.api.url}/organizations/${organization.id}/upload-instance-callback`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { blobUri },
      });

      onProgress({ percentage: 100 });
    },
    [config.api.url, organization.id, axios],
  );

  const fileUploader = useFileUpload({
    sendFile,
    concurrency: 12,
    autoStart: true,
  });

  return (
    <>
      <ModalHeader>
        <Flex align='start'>
          <Box>
            <Heading size='md'>Upload DICOM files</Heading>
            <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
              Please select some files to proceed
            </Text>
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <BulletSteps currentStep={2} totalSteps={2} />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </HStack>
        </Flex>
      </ModalHeader>
      <ModalBody overflowY='auto'>
        {P.isEmpty(fileUploader.files) && (
          <FileUploadControl
            multiple={true}
            files={fileUploader.files}
            onFilesChange={fileUploader.onFilesChange}
            status={fileUploader.status}
          />
        )}
        <FileUploadItemsProgress files={Object.values(fileUploader.fileProgress)} />
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button onClick={onBack}>Back</Button>
          <Button onClick={onClose}>Close</Button>
          <Button colorScheme='purple' onClick={() => fileUploader.reset()}>
            Reset
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};
