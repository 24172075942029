import {
  Textarea as ChakraTextarea,
  type TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import React from 'react';
import ResizeTextarea from 'react-textarea-autosize';

export interface TextAreaProps extends ChakraTextareaProps {
  minRows?: number;
  maxRows?: number;
}

export const TextArea = React.forwardRef(
  (props: TextAreaProps, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
    return (
      <ChakraTextarea
        resize='none'
        as={ResizeTextarea}
        minH='unset'
        ref={ref}
        minRows={1}
        maxRows={4}
        transition='none'
        rounded='md'
        focusBorderColor='purple.500'
        {...props}
      />
    );
  },
);

TextArea.displayName = 'TextArea';
