import { Box } from '@chakra-ui/react';
import type { User } from '@piccolohealth/echo-common';
import { stringParam, useQueryParams } from '@piccolohealth/ui';
import React from 'react';
import { useUsersQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { UserInviteButton } from '../../controls/UserInviteControl';
import { Content } from '../../generic/Content';
import { PiccoloError } from '../../generic/PiccoloError';
import { UsersSettingsFilter } from './UsersSettingsFilter';
import { UsersSettingsTable } from './UsersSettingsTable';

const DEFAULT_USER_NAME_FILTER = '';

export const UsersSettings = () => {
  const { organization } = useAppContext();

  const [params, setParams] = useQueryParams({
    name: stringParam,
  });

  const userNameFilter = params.name ?? DEFAULT_USER_NAME_FILTER;

  const setUserNameFilter = React.useCallback(
    (value: string) => {
      setParams({
        name: value,
      });
    },
    [setParams],
  );

  const { isFetching, data, error } = useUsersQuery({ organizationId: organization.id });

  if (error) {
    return <PiccoloError error={error} />;
  }

  const filteredUsers = ((data?.organization?.users as User[]) ?? []).filter((user: User) => {
    // Filter users without name or email
    const matchesNameOrEmail =
      new RegExp(userNameFilter, 'i').test(user.email) ||
      new RegExp(userNameFilter, 'i').test(user.name);

    return matchesNameOrEmail;
  });

  return (
    <Content title='Users' rightExtraContent={<UserInviteButton />}>
      <Box p={4}>
        <UsersSettingsFilter userNameFilter={userNameFilter} onUserNameFilter={setUserNameFilter} />
        <UsersSettingsTable users={filteredUsers} isLoading={isFetching} />
      </Box>
    </Content>
  );
};
