import { Card, CardBody, CardHeader, Divider, Heading, HStack, Stack } from '@chakra-ui/react';
import { ScrollArea, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Content } from '../../components/generic/Content';
import { PiccoloError } from '../../components/generic/PiccoloError';
import { InsightsBarTimeChart } from '../../features/insights/components/InsightsBarTimeChart';
import { InsightsFilters } from '../../features/insights/components/InsightsFilter';
import { InsightsPieChart } from '../../features/insights/components/InsightsPieChart';
import { InsightsTable } from '../../features/insights/components/InsightsTable';
import { InsightsValue } from '../../features/insights/components/InsightsValue';
import { useInsightsFilter } from '../../features/insights/hooks/useInsightsFilter';
import { useInsightsQuery } from '../../graphql/hooks/useInsightsQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const Insights = () => {
  const { organization } = useAppContext();

  const filter = useInsightsFilter();
  const { data, isLoading, error } = useInsightsQuery({
    organizationId: organization.id,
    request: {
      start: filter.startDate,
      end: filter.endDate,
      types: filter.reportTemplateType,
      cardiologists: filter.cardiologist,
      sonographers: filter.sonographer,
      referringPhysicians: filter.referringPhysician,
    },
  });

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <PiccoloError error={error} />;
    }

    const insights = data.organization?.insights;

    if (!insights) {
      return null;
    }

    return (
      <HStack w='full' h='full' spacing={4} minH={0}>
        <Stack w='full' h='full' flexGrow={1} spacing={4}>
          <HStack w='full' spacing={4}>
            <Card
              w='full'
              minW='180px'
              minH='120px'
              size='sm'
              borderWidth='1px'
              borderColor='gray.100'
              rounded='xl'
            >
              <CardHeader>
                <Heading size='sm'>Reports uploaded</Heading>
              </CardHeader>
              <CardBody>
                <InsightsValue value={insights.reportsUploaded} />
              </CardBody>
            </Card>
            <Card
              w='full'
              minW='180px'
              minH='120px'
              size='sm'
              borderWidth='1px'
              borderColor='gray.100'
              rounded='xl'
            >
              <CardHeader>
                <Heading size='sm'>Reports finalized</Heading>
              </CardHeader>
              <CardBody>
                <InsightsValue value={insights.reportsFinalized} />
              </CardBody>
            </Card>
            <Card
              w='full'
              minW='180px'
              minH='120px'
              size='sm'
              borderWidth='1px'
              borderColor='gray.100'
              rounded='xl'
            >
              <CardHeader>
                <Heading size='sm'>Reports shared</Heading>
              </CardHeader>
              <CardBody>
                <InsightsValue value={insights.reportsShared} />
              </CardBody>
            </Card>
            <Card
              w='full'
              minW='180px'
              minH='120px'
              size='sm'
              borderWidth='1px'
              borderColor='gray.100'
              rounded='xl'
            >
              <CardHeader>
                <Heading size='sm'>Unique patients</Heading>
              </CardHeader>
              <CardBody>
                <InsightsValue value={insights.uniquePatients} />
              </CardBody>
            </Card>
          </HStack>

          <Card w='full' h='full' size='sm' borderWidth='1px' borderColor='gray.100' rounded='xl'>
            <CardHeader>
              <Heading size='sm'>Reports uploaded</Heading>
            </CardHeader>
            <InsightsBarTimeChart
              data={insights.reportsUploadedOverTime}
              startDate={filter.startDate}
              endDate={filter.endDate}
            />
          </Card>
        </Stack>
        <ScrollArea w='sm' h='full' flexShrink={0}>
          <Stack>
            <Card borderWidth='1px' borderColor='gray.100' minH='2xs' rounded='xl'>
              <CardHeader>
                <Heading size='sm'>Sonographers</Heading>
              </CardHeader>
              <CardBody p={0}>
                <InsightsTable values={insights.sonographers} limit={10} />
              </CardBody>
            </Card>

            <Card borderWidth='1px' borderColor='gray.100' minH='2xs' rounded='xl'>
              <CardHeader>
                <Heading size='sm'>Cardiologists</Heading>
              </CardHeader>
              <CardBody p={0}>
                <InsightsTable values={insights.cardiologists} limit={10} />
              </CardBody>
            </Card>

            <Card borderWidth='1px' borderColor='gray.100' minH='2xs' rounded='xl'>
              <CardHeader>
                <Heading size='sm'>Referring doctors</Heading>
              </CardHeader>
              <CardBody p={0}>
                <InsightsTable values={insights.referringPhysicians} limit={10} />
              </CardBody>
            </Card>

            <Card borderWidth='1px' borderColor='gray.100' h='xs' rounded='xl'>
              <CardHeader>
                <Heading size='sm'>Report types</Heading>
              </CardHeader>
              <CardBody py={0}>
                <InsightsPieChart values={insights.reportsUploadedByType} />
              </CardBody>
            </Card>
          </Stack>
        </ScrollArea>
      </HStack>
    );
  });

  return (
    <Content title='Insights'>
      <Stack w='full' h='full' p={4} spacing={4} minH={0}>
        <InsightsFilters filter={filter} />
        <Divider />
        {content}
      </Stack>
    </Content>
  );
};
