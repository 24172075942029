import { Button, HStack, Icon, IconButton, Kbd, Spacer, Stack, Text } from '@chakra-ui/react';
import {
  type MedicalObjectsIntegrationProvider,
  type ProviderMapping,
  renderMedicalObjectsProviderName,
} from '@piccolohealth/echo-common';
import { type SelectComponents, type SelectOption, Spin } from '@piccolohealth/ui';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { useMedicalObjectsIntegrationPracticeProvidersQuery } from '../../../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { HookedAutoComplete } from '../../../forms/hookform/HookedAutoComplete';
import { HookedSelect } from '../../../forms/hookform/HookedSelect';
import { PiccoloError } from '../../../generic/PiccoloError';

interface Props {
  name: string;
}

type Mapping = ProviderMapping & { id: string };

export const MedicalObjectsProviderMappingsControl = (props: Props) => {
  const { name } = props;
  const { organization } = useAppContext();
  const { data, error, isLoading } = useMedicalObjectsIntegrationPracticeProvidersQuery({
    organizationId: organization.id,
  });
  const { fields, append, remove } = useFieldArray({
    name,
  });

  const mappings = fields as Mapping[];

  const integration = data?.organization?.integrations.medicalObjects;

  const users = data?.organization?.users ?? [];
  const providers = integration?.practiceProviders ?? [];

  const addMapping = React.useCallback(() => {
    append({ providerNumber: null, userId: null });
  }, [append]);

  const providerOptions: SelectOption<MedicalObjectsIntegrationProvider>[] = providers.map(
    (provider) => {
      return {
        raw: provider,
        label: renderMedicalObjectsProviderName(provider),
        value: provider.providerNumbers[0],
      };
    },
  );

  const userOptions = users.map((user) => ({
    key: user.id,
    value: user.id,
    label: user.name,
    raw: user.id,
  }));

  const components: SelectComponents<MedicalObjectsIntegrationProvider, unknown> = {
    Option: (props) => (
      <HStack>
        <Text>{renderMedicalObjectsProviderName(props.option.raw)}</Text>
        <Spacer />
        <Kbd fontSize='xs'>{props.option.value}</Kbd>
      </HStack>
    ),
    Value: (props) => (
      <HStack w='full' mr={1}>
        <Text>{renderMedicalObjectsProviderName(props.option.raw)}</Text>
        <Spacer />
        <Kbd fontSize='xs'>{props.option.value}</Kbd>
      </HStack>
    ),
  };

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <PiccoloError error={error} p={4} />;
  }

  return (
    <Stack>
      {mappings.map((mapping: Mapping, index: number) => {
        return (
          <HStack key={mapping.id}>
            <HookedAutoComplete
              name={`${name}[${index}].providerNumber`}
              options={providerOptions}
              components={components}
            />

            <HookedSelect
              name={`${name}[${index}].userId`}
              options={userOptions}
              placeholder='Select user'
            />
            <Spacer />
            <IconButton
              aria-label='Remove provider mapping'
              variant='unstyled'
              onClick={() => remove(index)}
              icon={<Icon as={FaTimes} />}
            />
          </HStack>
        );
      })}
      <Button size='sm' variant='outline' leftIcon={<Icon as={FaPlus} />} onClick={addMapping}>
        Add new mapping
      </Button>
    </Stack>
  );
};
