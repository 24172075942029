import type { SerializedNodes } from '@craftjs/core';
import type { ReportTemplate, ReportTemplateDryRun } from '@piccolohealth/echo-common';
import React from 'react';
import { useUpdateReportTemplateMutation } from '../../../graphql/hooks/useUpdateReportTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { Editor } from '../common/Editor';
import { RESOLVERS } from './helpers';
import * as toolbox from './Toolbox';

interface Props {
  reportTemplate: ReportTemplate;
  dryRun?: ReportTemplateDryRun;
}

export const ReportEditor = (props: Props) => {
  const { reportTemplate, dryRun } = props;
  const { organization, errorToast, successToast } = useAppContext();

  const mutation = useUpdateReportTemplateMutation();

  const componentGroups = [
    {
      name: 'General',
      components: [
        toolbox.components.checkboxGroup,
        toolbox.components.column,
        toolbox.components.divider,
        toolbox.components.image,
        toolbox.components.page,
        toolbox.components.text,
      ],
    },
    {
      name: 'Inline',
      components: [
        toolbox.components.toggleableSection,
        toolbox.components.manageStatementsButton,
        toolbox.components.measurement,
        toolbox.components.inlineInput,
        toolbox.components.inlineAutocomplete,
        toolbox.components.inlineDatepicker,
        toolbox.components.inlineSelect,
        toolbox.components.inlineStatementSite,
        toolbox.components.inlineStatementSiteList,
        toolbox.components.mediaAttachmentZone,
      ],
    },
    {
      name: 'Report',
      components: [toolbox.components.wallMotionDiagram],
    },
  ];

  const onSave = async (reportNodes: SerializedNodes) => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateId: reportTemplate.id,
        request: {
          name: reportTemplate.name,
          reportNodes,
        },
      })
      .then(() => {
        successToast('Successfully updated report template');
      })
      .catch((err) => {
        errorToast(`Error updating report template: ${err.message}`);
      });
  };

  return (
    <Editor
      resolvers={RESOLVERS}
      reportTemplate={reportTemplate}
      variables={dryRun?.variables ?? []}
      nodes={reportTemplate.reportNodes}
      componentGroups={componentGroups}
      onSave={onSave}
    />
  );
};
