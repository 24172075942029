import { Box, Button, Flex, Icon, Input } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { DividerWithContent } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useReport } from '../../../../context/ReportContext';
import { type BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { Column } from '../../common/components/Column';
import { Element } from '../../common/Element';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

export interface Props extends BaseNodeProps {
  variableId: string;
  text: string;
}

export const ToggleableSection = (props: Props) => {
  const { variableId, text, ssr, ...rest } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const { isDisabled } = useReport();

  const { field } = useController({ name: `variables.${variableId}.value` });

  const onClick = React.useCallback(() => {
    field.onChange(!field.value);
  }, [field]);

  return (
    <Box ref={(ref) => connect(drag(ref))} {...cleanProps(rest)}>
      {field.value ? (
        <Box>
          <Flex flexDir='row' align='start'>
            <Element
              id='top'
              canvas
              is={Column}
              {...Column.defaultProps}
              width='100%'
              ssr={props.ssr}
              linked={props.linked}
              nodes={props.nodes}
            />
            {!ssr && (
              <Button
                color='gray.400'
                variant='link'
                tabIndex={-1}
                isDisabled={isDisabled}
                onClick={onClick}
              >
                Remove
              </Button>
            )}
          </Flex>
          <Element
            id='bottom'
            canvas
            is={Column}
            {...Column.defaultProps}
            ssr={props.ssr}
            linked={props.linked}
            nodes={props.nodes}
          />
        </Box>
      ) : (
        !ssr && (
          <DividerWithContent py={2}>
            <Button
              isDisabled={isDisabled}
              variant='outline'
              tabIndex={-1}
              size='xs'
              color='gray.400'
              leftIcon={<Icon as={FaPlus} />}
              onClick={onClick}
            >
              {text}
            </Button>
          </DividerWithContent>
        )
      )}
    </Box>
  );
};

const ToggleableSectionSettings = () => {
  const {
    actions: { setProp },
    variableId,
    text,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    text: node.data.props.text,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title='ToggleableSection'>
        <FormItem id='variableId' label='Variable'>
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) =>
              setProp((props: Props) => {
                props.variableId = value;
              })
            }
          />
        </FormItem>
        <FormItem id='text' label='Text'>
          <Input
            defaultValue={text}
            onChange={(e) =>
              setProp((props: Props) => {
                props.text = e.target.value;
              })
            }
            size='sm'
          />
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

ToggleableSection.defaultProps = {
  variableId: '',
  text: 'Add',
};

ToggleableSection.craft = {
  name: 'ToggleableSection',
  props: ToggleableSection.defaultProps,
  related: {
    settings: ToggleableSectionSettings,
  },
};
