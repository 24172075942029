import { Input, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';

export interface NodeBorderProps {
  borderWidth?: string;
  borderRadius?: string;
  borderColor?: string;
}

export const NodeBorderSettings = () => {
  const {
    actions: { setProp },
    borderWidth,
    borderRadius,
    borderColor,
  } = useNode<NodeBorderProps>((node) => ({
    borderWidth: node.data.props.borderWidth,
    borderRadius: node.data.props.borderRadius,
    borderColor: node.data.props.borderColor,
  }));

  return (
    <SimpleGrid columns={2} spacing={8}>
      <FormItem id='borderWidth' label='Border width'>
        <Input
          defaultValue={borderWidth}
          onChange={(e) =>
            setProp((props: NodeBorderProps) => {
              props.borderWidth = e.target.value;
            })
          }
          size='sm'
        />
      </FormItem>
      <FormItem id='borderRadius' label='Border radius'>
        <Input
          defaultValue={borderRadius}
          onChange={(e) =>
            setProp((props: NodeBorderProps) => {
              props.borderRadius = e.target.value;
            })
          }
          size='sm'
        />
      </FormItem>
      <FormItem id='borderColor' label='Border color'>
        <Input
          defaultValue={borderColor}
          onChange={(e) =>
            setProp((props: NodeBorderProps) => {
              props.borderColor = e.target.value;
            })
          }
          size='sm'
        />
      </FormItem>
    </SimpleGrid>
  );
};

NodeBorderSettings.defaultProps = {
  borderWidth: undefined,
  borderRadius: undefined,
  borderColor: undefined,
};
