import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  type CreateReportsExportJobRequest,
  DicomExportOptions,
  type Job,
  JobStatus,
} from '@piccolohealth/echo-common';
import { P, prettyBytes } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaFileArchive, FaFileDownload, FaFilePdf, FaLaptopMedical, FaVideo } from 'react-icons/fa';
import { useAppContext } from '../../hooks/useAppContext';
import { type UseReportsExportJobResponse, useReportsExportJob } from '../../hooks/useJob';
import { FormSection } from '../forms/FormSection';
import { HookedCheckboxStack } from '../forms/hookform/HookedCheckboxStack';
import { HookedFormItem } from '../forms/hookform/HookedFormItem';
import { HookedRadioGroup } from '../forms/hookform/HookedRadioGroup';
import { HookedSubmitButton } from '../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../forms/hookform/HookedSwitch';
import { Divider } from '../generic/Divider';
import { createModal } from '../generic/Modal';
import { PiccoloError } from '../generic/PiccoloError';

type FormValues = {
  reportIds: string[];
  options: ('pdfs' | 'attachments' | 'images' | 'dicom')[];
  flattened: boolean;
  dicomOptions: DicomExportOptions;
};

interface Props {
  reportIds: string[];
}

const ReportsExportJobResultProgress = (props: { job: Job }) => {
  const { job } = props;

  const roundedProgress = P.round(job.progress, 0);
  const colorScheme = () => {
    switch (job.status) {
      case JobStatus.Queued:
      case JobStatus.InProgress:
        return 'purple';
      case JobStatus.Completed:
        return 'green';
      case JobStatus.Failed:
        return 'red';
    }
  };

  return (
    <Flex direction='row' alignItems='center' gridColumnGap={4}>
      <Progress value={roundedProgress} colorScheme={colorScheme()} rounded='xl' flexGrow={1} />
      <Text>{roundedProgress}%</Text>
    </Flex>
  );
};

const ReportsExportJobResultContent = (props: { exportJob: UseReportsExportJobResponse }) => {
  const { isLoading, isCompleted, job, error, isFailed } = props.exportJob;

  if (error) {
    return <PiccoloError error={error} />;
  }

  if (isFailed && job) {
    return (
      <Stack spacing={4}>
        <Text fontSize='sm'>{job.payload?.error}</Text>
        <ReportsExportJobResultProgress job={job} />
      </Stack>
    );
  }

  if (isLoading && job) {
    const text =
      job.status === JobStatus.InProgress
        ? 'Exporting files...'
        : 'Your export will begin processing soon...';

    return (
      <Stack spacing={6}>
        <Text fontSize='sm'>{text}</Text>
        <ReportsExportJobResultProgress job={job} />
      </Stack>
    );
  }

  if (isCompleted && job) {
    return (
      <Stack spacing={4}>
        <Text fontSize='sm'>Export was successfully completed</Text>
        <ReportsExportJobResultProgress job={job} />
        <Button
          as='a'
          href={job?.payload?.downloadUrl}
          key='downloadExport'
          width='full'
          size='lg'
          colorScheme='purple'
          variant='solid'
          rightIcon={<Icon as={FaFileDownload} />}
        >
          Download - {prettyBytes(job?.payload?.size || 0)}
        </Button>
      </Stack>
    );
  }

  return null;
};

export const ReportsExportModal = createModal<Props>((props) => {
  const { reportIds, modal } = props;
  const { organization } = useAppContext();
  const exportJob = useReportsExportJob();

  const initialValues: FormValues = {
    reportIds,
    options: [],
    flattened: false,
    dicomOptions: DicomExportOptions.All,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
  });

  const watchedOptions = methods.watch('options') as string[];
  const showDicomOptions = watchedOptions.includes('dicom');

  const onSubmit = (values: FormValues) => {
    const options = (values.options ?? []).reduce<Record<string, boolean>>((acc, option) => {
      acc[option] = true;
      return acc;
    }, {});

    const request: CreateReportsExportJobRequest = {
      ...options,
      reportIds: values.reportIds,
      flattened: values.flattened,
      dicom: values.options.includes('dicom') ? values.dicomOptions : undefined,
    };

    const variables = {
      organizationId: organization.id,
      request,
    };

    exportJob.start(variables);
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent data-pw='reportsExportModalContent'>
        <ModalHeader>Export</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection layout='vertical' heading='Files to export'>
                <HookedCheckboxStack
                  name='options'
                  options={[
                    {
                      label: 'PDFs',
                      value: 'pdfs',
                      description: 'PDFs that were generated after finalizing a report',
                      icon: <Icon as={FaFilePdf} />,
                    },
                    {
                      label: 'Attachments',
                      value: 'attachments',
                      description:
                        'Attachments that were added to a report as part of the reporting process',
                      icon: <Icon as={FaFileArchive} />,
                    },
                    {
                      label: 'Images & Videos',
                      value: 'images',
                      description: 'Images and videos for a report',
                      icon: <Icon as={FaVideo} />,
                    },
                    {
                      label: 'DICOM',
                      value: 'dicom',
                      description: 'DICOM files for a report',
                      icon: <Icon as={FaLaptopMedical} />,
                    },
                  ]}
                  data-pw='reportsExportModalOptions'
                />
              </FormSection>
              <Divider />
              <FormSection layout='vertical' heading='Additional options'>
                <HookedFormItem
                  layout='horizontal'
                  name='flattened'
                  label='Flattened'
                  helperText='Export all files to a single folder'
                >
                  <HookedSwitch colorScheme='purple' key='flattened' name='flattened' />
                </HookedFormItem>
                {showDicomOptions && (
                  <HookedFormItem
                    layout='horizontal'
                    name='dicomOptions'
                    label='DICOM Options'
                    helperText='Select what DICOM files to include in the export'
                  >
                    <HookedRadioGroup
                      name='dicomOptions'
                      size='xs'
                      options={[
                        { label: 'All', value: DicomExportOptions.All },
                        { label: 'SR', value: DicomExportOptions.Sr },
                        { label: 'US', value: DicomExportOptions.Us },
                      ]}
                    />
                  </HookedFormItem>
                )}
              </FormSection>
              <Divider />
              <ReportsExportJobResultContent exportJob={exportJob} />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton
                  data-pw='reportsExportModalSubmitButton'
                  isLoading={exportJob.isLoading}
                >
                  Export
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
