import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as Common from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFaxIntegrationSettingsQuery } from '../../../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { createModal } from '../../../generic/Modal';
import { PiccoloError } from '../../../generic/PiccoloError';
import { IntegrationStatus } from '../IntegrationStatus';
import { FaxIntegrationForm } from './FaxIntegrationForm';

interface Props {
  title: string;
  description: string;
  logo: string;
  isIntegrationEnabled: boolean;
}

export const FaxIntegrationModal = createModal<Props>((props) => {
  const { modal, title, description, logo, isIntegrationEnabled } = props;
  const { organization } = useAppContext();

  const { data, error, isLoading } = useFaxIntegrationSettingsQuery({
    organizationId: organization.id,
  });

  const integration = data?.organization?.integrations.fax;

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    } else if (error) {
      return <PiccoloError error={error} p={4} />;
    } else if (P.isUndefined(integration)) {
      return (
        <PiccoloError
          error={
            new Common.PiccoloError({
              type: 'IntegrationNotFound',
              message: 'Integration not found',
            })
          }
          p={4}
        />
      );
    } else {
      return <FaxIntegrationForm initialValues={integration.settings} hide={modal.hide} />;
    }
  });

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth='1px'>
          <Box mr={8}>
            <IntegrationStatus
              title={title}
              description={description}
              logo={logo}
              isIntegrationEnabled={isIntegrationEnabled}
            />
          </Box>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
      </ModalContent>
    </Modal>
  );
});
