import { Tag, type TagProps } from '@chakra-ui/react';
import { OrganizationStatus } from '@piccolohealth/echo-common';
import React from 'react';

export const getColorAndText = (status: OrganizationStatus) => {
  switch (status) {
    case OrganizationStatus.Trial:
      return { color: 'yellow', text: 'Trial' };
    case OrganizationStatus.Active:
      return { color: 'green', text: 'Active' };
    case OrganizationStatus.NonBillable:
      return { color: 'blue', text: 'Non billable' };
    case OrganizationStatus.Archived:
      return { color: 'gray', text: 'Archived' };
  }
};

interface Props extends TagProps {
  status: OrganizationStatus;
}

export const OrganizationStatusTag = (props: Props) => {
  const { status, ...rest } = props;
  const { color, text } = getColorAndText(status);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
