import { Input, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';

export interface NodeBackgroundProps {
  backgroundColor?: string;
}

export const NodeBackgroundSettings = () => {
  const {
    actions: { setProp },
    backgroundColor,
  } = useNode<NodeBackgroundProps>((node) => ({
    backgroundColor: node.data.props.backgroundColor,
  }));

  return (
    <SimpleGrid columns={2} spacing={8}>
      <FormItem id='backgroundColor' label='Background color'>
        <Input
          defaultValue={backgroundColor}
          onChange={(e) =>
            setProp((props: NodeBackgroundProps) => {
              props.backgroundColor = e.target.value;
            })
          }
          size='sm'
        />
      </FormItem>
    </SimpleGrid>
  );
};

NodeBackgroundSettings.defaultProps = {
  backgroundColor: undefined,
};
