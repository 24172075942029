import * as ucum from '@lhncbc/ucum-lhc';
import { P } from '@piccolohealth/util';

export type UCUMUnit = {
  csCode_: string;
  isBase_: boolean;
  loincProperty_: string;
};

export const digitFromSuperscript = (superChar: string): string => {
  const result = '⁰¹²³⁴⁵⁶⁷⁸⁹'.indexOf(superChar);

  if (result > -1) {
    return result.toString();
  } else {
    return superChar;
  }
};

export const getAllUCUMUnitByLoincProperty = (loincProperty: string): UCUMUnit[] => {
  const instance = ucum.UnitTables.getInstance();
  const isUnitsLoaded = instance.unitsCount() > 0;

  if (!isUnitsLoaded) {
    // Force the lazy loading from dogshit library
    ucum.UcumLhcUtils.getInstance().validateUnitString('cm');
  }

  return instance
    .getAllUnitCodes()
    .map((u: string) => instance.getUnitByCode(u))
    .filter((u: UCUMUnit) => u.loincProperty_ === loincProperty);
};

export const sanitizeUnit = (unit: string) => {
  const nonSuperscript = unit.replace(/[⁰¹²³⁴⁵⁶⁷⁸⁹]/g, digitFromSuperscript);

  switch (nonSuperscript.toLowerCase()) {
    case 'milliliter':
      return 'mL';
    case 'centimeter':
      return 'cm';
    case 'centimeter2':
      return 'cm2';
    case 'ml/m²':
      return 'ml/m2';
    case 'meter/second':
      return 'm/s';
    case 'percent':
      return '%';
    case 'millimeter of mercury column':
      return 'mm[Hg]';
    case 'mmhg':
      return 'mm[Hg]';
    default:
      return nonSuperscript;
  }
};

export const convertMeasurementValue = (value: any, fromUnit: string, toUnit: string) => {
  const fromUnitSanitized = sanitizeUnit(fromUnit);
  const toUnitSanitized = sanitizeUnit(toUnit);

  const convertedValue = ucum.UcumLhcUtils.getInstance().convertUnitTo(
    fromUnitSanitized,
    value,
    toUnitSanitized,
  );

  if (convertedValue.status === 'succeeded') {
    return convertedValue.toVal;
  } else {
    return value;
  }
};

export const roundMeasurementValue = (value: any, precision: number): number => {
  return P.isNumber(value) ? P.round(value, precision) : value;
};

// Added this because of VINNO machines. They do not send an imageType.
// In retrospect, imageType is probably not the right thing to do anyway
export const isPlayableDicom = (options: {
  modality: string;
  transferSyntaxUID?: string;
  imageType?: string;
}): boolean => {
  const { modality, transferSyntaxUID, imageType } = options;

  if (modality === 'SR') {
    return false;
  }

  if (!transferSyntaxUID && !imageType) {
    return false;
  }

  // If there's an image type, assume playable
  if (!P.isNil(imageType)) {
    return true;
  }

  // If there's no image type, check the transfer syntax
  //https://www.dicomlibrary.com/dicom/transfer-syntax/
  switch (transferSyntaxUID) {
    // JPEG Baseline (Process 1): Default Transfer Syntax for Lossy JPEG 8-bit Image Compression.
    case '1.2.840.10008.1.2.4.50':
      return true;
    // JPEG Extended (Process 2 & 4): Default Transfer Syntax for Lossy JPEG 12-bit Image Compression (Process 4 only).
    case '1.2.840.10008.1.2.4.51':
      return true;
    // JPEG Lossless, Non-Hierarchical (Processes 14).
    case '1.2.840.10008.1.2.4.57':
      return true;
    //JPEG Lossless, Nonhierarchical, First- Order Prediction
    case '1.2.840.10008.1.2.4.70':
      return true;
    // JPEG-LS Lossless Image Compression
    case '1.2.840.10008.1.2.4.80':
      return true;
    // JPEG-LS Lossy (Near- Lossless) Image Compression
    case '1.2.840.10008.1.2.4.81':
      return true;
    // JPEG 2000 Image Compression (Lossless Only).
    case '1.2.840.10008.1.2.4.90':
      return true;
    // JPEG 2000 Image Compression.
    case '1.2.840.10008.1.2.4.91':
      return true;
    // MPEG2 Main Profile / Main Level.
    case '1.2.840.10008.1.2.4.100':
      return true;
    // MPEG2 Main Profile / High Level.
    case '1.2.840.10008.1.2.4.101':
      return true;
    // MPEG-4 AVC/H.264 High Profile / Level 4.1.
    case '1.2.840.10008.1.2.4.102':
      return true;
    // MPEG-4 AVC/H.264 BD-compatible High Profile / Level 4.1.
    case '1.2.840.10008.1.2.4.103':
      return true;
    default:
      return false;
  }
};
