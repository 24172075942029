import { Text } from '@chakra-ui/react';
import type { AuditTrailEntry, AuditTrailEvent, PiccoloError } from '@piccolohealth/echo-common';
import {
  createColumnHelper,
  DataTable,
  FancyDate,
  type TimestampPaginationOptions,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { UserDescription } from '../user/UserDescription';
import { AuditTrailEntryInfoControl } from './AuditTrailEntryInfoModal';
import { AuditTrailEventTag } from './AuditTrailEventTag';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null | undefined;
  pagination?: TimestampPaginationOptions;
  entries: AuditTrailEntry[];
  refetch: () => Promise<void>;
}

export const AuditTrailTable = (props: Props) => {
  const { isLoading, error, pagination, entries } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<AuditTrailEntry>();

    return [
      columnHelper.display({
        id: 'who',
        header: 'Who',
        cell: (ps) => {
          return (
            <UserDescription
              name={ps.row.original.user.name}
              email={ps.row.original.user.email}
              picture={ps.row.original.user.picture}
            />
          );
        },
        minSize: 200,
        maxSize: 500,
      }),
      columnHelper.display({
        header: 'Event',
        cell: (ps) => {
          return <AuditTrailEventTag event={ps.row.original.event as AuditTrailEvent} />;
        },
        minSize: 150,
        maxSize: 320,
      }),
      columnHelper.display({
        header: 'Action',
        cell: (ps) => {
          return <Text>{ps.row.original.message}</Text>;
        },
      }),
      columnHelper.display({
        header: 'Time',
        cell: (ps) => (
          <FancyDate date={DateTime.fromISO(ps.row.original.createdAt as unknown as string)} />
        ),
        maxSize: 230,
        minSize: 230,
      }),
      columnHelper.display({
        header: 'Info',
        cell: (ps) => {
          return <AuditTrailEntryInfoControl entry={ps.row.original} />;
        },
        maxSize: 140,
      }),
    ];
  }, []);

  return (
    <DataTable
      columns={columns}
      isLoading={isLoading}
      error={error}
      pagination={pagination}
      data={entries}
      size='sm'
    />
  );
};
