import { Box, Flex } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { OrganizationNavbar } from '../../../components/nav/OrganizationNavbar';
import { OrganizationContextProvider } from '../../../context/OrganizationContext';

export const OrganizationLayout = () => {
  return (
    <OrganizationContextProvider>
      <NiceModal.Provider>
        <Flex h='full' flexDir='column'>
          <OrganizationNavbar />
          <Box h='full' flexGrow={1} overflowY='auto' bg='white'>
            <Outlet />
          </Box>
        </Flex>
      </NiceModal.Provider>
    </OrganizationContextProvider>
  );
};
