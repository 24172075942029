import { Button, HStack, Icon, IconButton, Spacer, Stack } from '@chakra-ui/react';
import type { ProviderMapping } from '@piccolohealth/echo-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { useUsersMinimalQuery } from '../../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { HookedSelect } from '../../../forms/hookform/HookedSelect';
import { PiccoloError } from '../../../generic/PiccoloError';

interface Props {
  name: string;
}

type Mapping = ProviderMapping & { id: string };

export const HealthLinkProviderMappingsControl = (props: Props) => {
  const { name } = props;
  const { organization } = useAppContext();
  const { data, error, isLoading } = useUsersMinimalQuery({
    organizationId: organization.id,
  });
  const { fields, append, remove } = useFieldArray({
    name,
  });

  const mappings = fields as Mapping[];

  const users = data?.organization?.users ?? [];

  const addMapping = React.useCallback(() => {
    append({ providerNumber: null, userId: null });
  }, [append]);

  const userOptions = users.map((user) => ({
    key: user.id,
    value: user.id,
    label: user.name,
    raw: user.id,
  }));

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <PiccoloError error={error} p={4} />;
  }

  return (
    <Stack>
      {mappings.map((mapping: Mapping, index: number) => {
        return (
          <HStack key={mapping.id}>
            <HookedInput name={`${name}[${index}].providerNumber`} />
            <HookedSelect
              name={`${name}[${index}].userId`}
              options={userOptions}
              placeholder='Select user'
            />
            <Spacer />
            <IconButton
              aria-label='Remove provider mapping'
              variant='unstyled'
              onClick={() => remove(index)}
              icon={<Icon as={FaTimes} />}
            />
          </HStack>
        );
      })}
      <Button size='sm' variant='outline' leftIcon={<Icon as={FaPlus} />} onClick={addMapping}>
        Add new mapping
      </Button>
    </Stack>
  );
};
