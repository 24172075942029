import { Async, P } from '@piccolohealth/util';
import { v4 as uuidv4, v5 } from 'uuid';

export const uuid = () => uuidv4();
export const uuidv5 = (name: string, namespace: string) => v5(name, namespace);

export type JSONPrimitive = string | number | boolean | null;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;
export type JSONObject = { [member: string]: JSONValue };
export interface JSONArray extends Array<JSONValue> {}

export interface LooseObject {
  [key: string]: any;
}

export type AsyncReturnType<T extends (...args: any) => any> = Awaited<ReturnType<T>>;

export const intersperseMenuItems = <T>(arr: T[][], separator: (n: number) => T): T[] => {
  const compacted = arr.map((array) => P.compact(array));
  const rejected = compacted.filter((item) => !P.isEmpty(item));
  const interspersed = P.intersperse(rejected, (n) => [separator(n)]);
  return P.flatten(interspersed);
};

export const nullToUndefined = (value: any): any => {
  if (P.isPlainObject(value)) {
    return P.mapValues(value, nullToUndefined);
  }
  if (P.isArray(value)) {
    return value.map(nullToUndefined);
  }
  if (value === null) {
    return undefined;
  }
  return value;
};

export const randomString = (length: number) => {
  return Math.random().toString(36).slice(2).slice(0, length);
};

export const pollUntil = async <A>(
  fn: () => Promise<A>,
  condition: (result: A) => boolean,
  delay: number,
  maxAttempts = 30,
): Promise<A> => {
  const go = async (
    fn: () => Promise<A>,
    condition: (result: A) => boolean,
    delay: number,
    attempts: number,
  ): Promise<A> => {
    const result = await fn();

    if (condition(result)) {
      return result;
    } else if (attempts === maxAttempts) {
      return Promise.reject(new Error('Too many attempts'));
    } else {
      await Async.delay(delay);
      return go(fn, condition, delay, attempts + 1);
    }
  };

  return go(fn, condition, delay, 1);
};

export const replaceWhere = <A>(
  list: A[],
  predicate: (value: A) => boolean,
  replacement: (value: A) => A,
) => {
  return list.map((item) => (predicate(item) ? replacement(item) : item));
};

export const shallowObjDiff = (prev: LooseObject, curr: LooseObject): LooseObject => {
  return curr.filter((currV: unknown, currK: string) => {
    return !P.isEqual(P.get(prev, currK), currV);
  });
};

export const reorder = <A>(list: A[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const mergeCopyArrays = (objValue: LooseObject, srcValue: LooseObject) => {
  if (P.isArray(objValue)) {
    return srcValue;
  }
};

export const getPercentChange = (current: number, previous: number) => {
  const finalPercent: number = P.run(() => {
    if (previous !== 0) {
      if (current !== 0) {
        return ((previous - current) / current) * 100;
      } else {
        return previous * 100;
      }
    } else {
      const percent = -current * 100;

      if (percent === 0) {
        return 0;
      }

      return percent;
    }
  });

  return Math.floor(finalPercent);
};

export const toSentence = (arr: string[]) => {
  return (
    arr.slice(0, -2).join(', ') +
    (arr.slice(0, -2).length ? ', ' : '') +
    arr.slice(-2).join(' and ')
  );
};

export const safeJsonParse = (input: string) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return input;
  }
};

export const generateShortId = () => {
  const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const generator = (base: string, len: number) => {
    return [...Array(len)].map(() => base[(Math.random() * base.length) | 0]).join('');
  };

  return generator(base, 8);
};

export const debug = (value: any) => {
  return JSON.stringify(value, null, 2);
};
