import { HStack, Text, VStack } from '@chakra-ui/react';
import type { ReportTemplate, ReportTemplateVariable } from '@piccolohealth/echo-common';
import { createColumnHelper, DataTable, type PaginationOptions } from '@piccolohealth/ui';
import React from 'react';
import { ReportTemplateVariableDeleteButton } from './ReportTemplateVariableDeleteControl';
import { ReportTemplateVariableEditButton } from './ReportTemplateVariableEditControl';
import { ReportTemplateVariableSourceTag } from './ReportTemplateVariableSourceTag';
import { ReportTemplateVariableTypeTag } from './ReportTemplateVariableTypeTag';

interface Props {
  reportTemplate: ReportTemplate;
  isLoading: boolean;
  errors: never[];
  pagination: PaginationOptions;
  variables: ReportTemplateVariable[];
  // set as this type as type of _.noop used in useVariablesQuery
  refetch: () => void;
}

export const ReportTemplateVariablesTable = (props: Props) => {
  const { reportTemplate, isLoading, pagination, variables } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ReportTemplateVariable>();

    return [
      columnHelper.display({
        header: 'ID/Label',
        minSize: 300,
        maxSize: 700,
        cell: (ps) => (
          <VStack spacing={2} align='start'>
            <Text fontSize='md' fontWeight='bold'>
              {ps.row.original.id}
            </Text>
            <Text fontSize='md' color='secondary'>
              {ps.row.original.label}
            </Text>
          </VStack>
        ),
      }),
      columnHelper.display({
        header: 'Source',
        maxSize: 140,
        cell: (ps) => <ReportTemplateVariableSourceTag source={ps.row.original.source} />,
      }),
      columnHelper.display({
        header: 'Type',
        minSize: 100,
        cell: (ps) => <ReportTemplateVariableTypeTag type={ps.row.original.__typename} />,
      }),
      columnHelper.display({
        header: 'Actions',
        maxSize: 200,
        cell: (ps) => (
          <HStack>
            <ReportTemplateVariableEditButton
              reportTemplateId={reportTemplate.id}
              variable={ps.row.original}
            />
            <ReportTemplateVariableDeleteButton
              organizationId={reportTemplate.organizationId}
              reportTemplateId={reportTemplate.id}
              variable={ps.row.original}
            />
          </HStack>
        ),
      }),
    ];
  }, [reportTemplate]);

  return (
    <DataTable
      columns={columns}
      data={variables}
      isLoading={isLoading}
      pagination={pagination}
      size='sm'
    />
  );
};
