import { Stack } from '@chakra-ui/react';
import { type Distribution } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Divider } from '../../../components/generic/Divider';
import { DistributionTooltipSummary } from './DistributionTooltipSummary';

interface Props {
  distributions: Distribution[];
}

export const DistributionTooltip = (props: Props) => {
  const { distributions } = props;

  return (
    <Stack>
      {P.intersperse(
        distributions.map((distribution) => (
          <DistributionTooltipSummary key={distribution.id} distribution={distribution} />
        )),
        () => (
          <Divider borderColor='secondary' />
        ),
      )}
    </Stack>
  );
};
