import { Divider, HStack, IconButton, Spacer, Stack, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
  FaRegQuestionCircle,
} from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

interface ShortcutProps {
  description: string;
  shortcut: string | React.ReactNode;
}

const Shortcut = (props: ShortcutProps) => {
  return (
    <HStack>
      <Text>{props.description}</Text>
      <Spacer />
      <Tag size='sm' as='kbd' fontWeight='semibold'>
        {props.shortcut}
      </Tag>
    </HStack>
  );
};

export const KeyboardShortcutsControl = () => {
  return (
    <ControlTooltip
      placement='bottom-end'
      label={
        <Stack spacing={2} p={2}>
          <Text fontSize='lg' fontWeight='semibold'>
            Keyboard shortcuts
          </Text>

          <Divider />

          <Stack spacing={1}>
            <Shortcut description='Next clip' shortcut={<FaArrowRight />} />
            <Shortcut description='Previous clip' shortcut={<FaArrowLeft />} />
            <Shortcut description='Next frame' shortcut={<FaArrowDown />} />
            <Shortcut description='Previous frame' shortcut={<FaArrowUp />} />
            <Shortcut description='Play / pause' shortcut='Space' />
          </Stack>
        </Stack>
      }
    >
      <IconButton
        aria-label='Keyboard shortcuts'
        icon={<FaRegQuestionCircle />}
        size='sm'
        fontSize='xl'
        variant='imageViewer'
        colorScheme='purple'
      />
    </ControlTooltip>
  );
};
