import {
  convertMeasurementValue,
  FeatureFlag,
  getRangeResult,
  getReportTemplateVariableByAlias,
  getReportTemplateVariableById,
  getReportVariableValueAsDateTime,
  getReportVariableValueAsNumber,
  getReportVariableValueAsString,
  isReportStaticVariable,
  isReportTemplateStaticVariable,
  type MeasurementRangeCriteria,
  type MeasurementRangeResult,
  type Report,
  type ReportStaticVariable,
  type ReportTemplate,
  type ReportTemplateStaticVariable,
  type ReportVariable,
  roundMeasurementValue,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { GREEN } from '../components/templates/report/components/attachments/media/chart/ChartAttachmentForm';
import type { MeasurementSeries } from '../features/measurements/MeasurementsChart';
import { useReportRelatedVariablesQuery } from '../graphql/hooks/useReportQuery';
import { useAppContext } from './useAppContext';

const DEFAULT_LIMIT = 10;

export interface GetHistoryOptions {
  limit?: number;
}

export interface HistoricalVariables {
  getHistory: (variableId: string, options?: GetHistoryOptions) => VariableHistory | undefined;
}

export interface GetHistoryOptions {
  limit?: number;
}

export interface HistoricalVariables {
  getHistory: (variableId: string, options?: GetHistoryOptions) => VariableHistory | undefined;
}

export interface UseHistoricalVariablesOptions {
  organizationId: string;
  reportId: string;
  reportTemplate: ReportTemplate;
}

export interface HistoricalVariableChartData {
  series: MeasurementSeries[];
  units: string | null;
  isGraphable: boolean;
  color: string;
  reportTemplateVariable: ReportTemplateStaticVariable;
  rangeInfo: {
    criteria: MeasurementRangeCriteria;
    result: MeasurementRangeResult | null;
  };
}

export interface HistoricalVariable {
  variable: ReportStaticVariable;
  templateVariable: ReportTemplateStaticVariable;
  alias: string;
  reportId: string;
  createdAt: Date;
  isEmpty: boolean;
}

export interface VariableHistory {
  reportTemplateVariable: ReportTemplateStaticVariable;
  current: HistoricalVariable;
  previous: HistoricalVariable[];
  all: HistoricalVariable[];
  allNonNil: HistoricalVariable[];
  allGraphable: HistoricalVariable[];
  chart: HistoricalVariableChartData;
}

const getHistoricalVariables = (
  report: Report,
  reportTemplate: ReportTemplate,
  variables: ReportVariable[],
  relatedReports: Report[],
): {
  currentVariables: HistoricalVariable[];
  previousVariables: HistoricalVariable[];
  allVariables: {
    reportTemplateVariable: ReportTemplateStaticVariable | undefined;
    variables: HistoricalVariable[];
  }[];
} => {
  const isMeasurement = (v: ReportStaticVariable) => {
    const reportTemplateVariable = v.alias
      ? getReportTemplateVariableByAlias(reportTemplate.variables, v.alias)
      : undefined;
    return reportTemplateVariable?.category === 'Measurement';
  };
  const isEmpty = (v: ReportVariable) => P.isNil(v.value) || v.value === '';

  const studyCreatedAt =
    getReportVariableValueAsDateTime(report.variables, 'studyDate')?.toJSDate() ??
    new Date(report.createdAt.toString());

  const staticVariables = variables.filter(isReportStaticVariable);

  const staticReportTemplateVariables = reportTemplate.variables.filter(
    isReportTemplateStaticVariable,
  ) as ReportTemplateStaticVariable[];

  // Convert the base set of variables to historical variables
  const currentVariables: HistoricalVariable[] = P.run(() => {
    const measurements = staticVariables.filter(isMeasurement);

    const historicalMeasurements = measurements.flatMap((variable) => {
      const templateVariable = variable.alias
        ? getReportTemplateVariableByAlias(staticReportTemplateVariables, variable.alias)
        : null;

      if (!templateVariable || !templateVariable.alias) {
        return [];
      }

      return {
        variable: variable,
        templateVariable,
        alias: templateVariable.alias,
        createdAt: studyCreatedAt,
        reportId: report.id,
        isEmpty: isEmpty(variable),
      };
    });

    return P.compact(historicalMeasurements);
  });

  // Convert the related reports to historical variables
  const previousVariables: HistoricalVariable[] = P.run(() => {
    return relatedReports.flatMap((relatedReport) => {
      const relatedStudyCreatedAt =
        getReportVariableValueAsDateTime(relatedReport.variables, 'studyDate')?.toJSDate() ??
        new Date(relatedReport.createdAt.toString());

      const staticVariables = relatedReport.variables.filter(isReportStaticVariable);

      const measurements = staticVariables.filter(isMeasurement);

      return measurements.flatMap((v) => {
        const templateVariable = (
          v.alias ? getReportTemplateVariableByAlias(reportTemplate.variables, v.alias) : undefined
        ) as ReportTemplateStaticVariable | undefined;

        if (!templateVariable || !templateVariable?.alias) {
          return [];
        }

        const fromUnits = templateVariable?.units;
        const toUnits = templateVariable?.units;
        const convertedValue =
          fromUnits && toUnits
            ? roundMeasurementValue(convertMeasurementValue(v.value, fromUnits, toUnits), 2)
            : v.value;

        const variable = {
          ...v,
          value: convertedValue,
        };

        return {
          variable,
          templateVariable,
          alias: templateVariable.alias,
          createdAt: relatedStudyCreatedAt,
          reportId: relatedReport.id,
          isEmpty: isEmpty(v),
        };
      });
    });
  });

  // Combine the current and previous variables.
  // Add the reportTemplateVariable to the historical variables
  // Sort each historical variable by the createdAt date
  // Sort each group of variables by the site and label
  const allVariables = P.run(() => {
    const groupedVariables = P.groupBy([...currentVariables, ...previousVariables], (v) => v.alias);
    const historicalMeasurements = Object.values(
      P.mapValues(groupedVariables, (vs) => {
        const historicalVariables = vs as HistoricalVariable[];

        const reportTemplateVariable = historicalVariables[0]?.templateVariable;

        const variables = P.orderBy(
          historicalVariables.filter((v) => isMeasurement(v.variable)),
          (v) => v.createdAt.getMilliseconds(),
        ).reverse();

        return {
          reportTemplateVariable,
          variables,
        };
      }),
    );

    return P.orderBy(
      historicalMeasurements,
      (v) =>
        `${(v.reportTemplateVariable as ReportTemplateStaticVariable)?.site} ${
          v.reportTemplateVariable?.label
        }`,
    );
  });

  return { currentVariables, previousVariables, allVariables };
};

export interface UseHistoricalVariablesResponse {
  isLoading: boolean;
  allVariables: {
    reportTemplateVariable: ReportTemplateStaticVariable | undefined;
    variables: HistoricalVariable[];
  }[];
  currentVariables: HistoricalVariable[];
  previousVariables: HistoricalVariable[];
  getHistory: (variableId: string, options?: GetHistoryOptions) => VariableHistory | undefined;
}

export const useHistoricalVariables = (
  options: UseHistoricalVariablesOptions,
): UseHistoricalVariablesResponse => {
  const { organizationId, reportId, reportTemplate } = options;

  const {
    organization: { hasFeature },
  } = useAppContext();

  const variablesObj = useWatch({ name: 'variables' }) as Record<string, ReportVariable>;

  const { isLoading, isFetching, data } = useReportRelatedVariablesQuery({
    organizationId: organizationId,
    reportId: reportId,
  });

  const report = data?.organization?.report as Report;

  const { currentVariables, previousVariables, allVariables } = React.useMemo(() => {
    if (isLoading || !report) {
      return {
        currentVariables: [],
        previousVariables: [],
        allVariables: [
          {
            reportTemplateVariable: undefined,
            variables: [],
          },
        ],
      };
    }
    const relatedReports = (report?.related as Report[]) || [];
    const variables = Object.values(variablesObj);

    return getHistoricalVariables(report, reportTemplate, variables, relatedReports);
  }, [isLoading, report, reportTemplate, variablesObj]);

  const getHistory = React.useCallback(
    (id: string, options?: GetHistoryOptions) => {
      const staticReportTemplateVariables = reportTemplate.variables.filter(
        isReportTemplateStaticVariable,
      );
      const reportTemplateVariable = getReportTemplateVariableById(
        staticReportTemplateVariables,
        id,
      );

      const current: HistoricalVariable | undefined = currentVariables.find(
        (v) => v.variable.id === id,
      );

      if (!reportTemplateVariable || !current) {
        return undefined;
      }

      const previous: HistoricalVariable[] = previousVariables.filter(
        (v) => v.variable.alias === reportTemplateVariable.alias,
      );

      const all: HistoricalVariable[] = P.take(
        [current, ...previous],
        options?.limit ?? DEFAULT_LIMIT,
      );

      const allNonNil = all.filter((h) => !h.isEmpty);
      const allGraphable = allNonNil.filter((h) => !P.isStrictNaN(h.variable.value));

      const rangeInfo = P.run(() => {
        const variables = Object.values(variablesObj);

        const result = hasFeature(FeatureFlag.NormalRanges)
          ? getRangeResult(id, reportTemplateVariable, variables)
          : null;

        const criteria = {
          age: getReportVariableValueAsNumber(variables, 'age') ?? null,
          sex: getReportVariableValueAsString(variables, 'sex') ?? null,
          measurement: getReportVariableValueAsNumber(variables, id),
        };

        return {
          criteria,
          result,
        };
      });

      const chart: HistoricalVariableChartData = {
        series: [
          {
            id: reportTemplateVariable.label,

            data: P.orderBy(allGraphable, (v) => v.createdAt, 'desc')
              .map((v) => ({
                x: v.createdAt.toISOString(),
                y: v.variable.value,
              }))
              .reverse(),
          },
        ],
        units: (reportTemplateVariable as ReportTemplateStaticVariable | undefined)?.units ?? null,
        // If there aren't atleast 2 data points to connect, don't
        // graph the data since it doesn't give a helpful visualization
        isGraphable: allGraphable.length > 1,
        color: GREEN,
        reportTemplateVariable,
        rangeInfo,
      };

      return {
        reportTemplateVariable,
        current,
        previous,
        all,
        allNonNil,
        allGraphable,
        chart,
      };
    },
    [currentVariables, hasFeature, previousVariables, reportTemplate.variables, variablesObj],
  );

  return {
    isLoading: isLoading || isFetching,
    allVariables,
    currentVariables,
    previousVariables,
    getHistory,
  };
};
