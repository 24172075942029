import {
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Kbd,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { type Organization, OrganizationStatus, PERMISSIONS } from '@piccolohealth/echo-common';
import { ScrollAreaAutosize, type SelectOption, Spin, useOptionsFocus } from '@piccolohealth/ui';
import { matchSorter } from '@piccolohealth/util';
import keyboardJS from 'keyboardjs';
import React from 'react';
import { FaAngleDown, FaArrowRight, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { createModal, showModal } from '../../../components/generic/Modal';
import { PiccoloIcon } from '../../../components/generic/PiccoloIcon';
import { useOrganizationsQuery } from '../../../graphql/hooks/useOrganizationsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { VisibleWithPermission } from '../../../components/generic/VisibleWithPermission';
import { OrganizationStatusTag } from './OrganizationStatusTag';

interface OrganizationItemProps {
  name: string;
  logo?: string;
  isSelected?: boolean;
  isDefault?: boolean;
  status: OrganizationStatus;
}

const OrganizationItem = (props: OrganizationItemProps) => {
  const { name, logo, isSelected, isDefault, status } = props;
  return (
    <HStack spacing={4}>
      <Avatar
        w='42px'
        h='42px'
        p='2px'
        color='purple.600'
        borderRadius='6px'
        fontWeight='600'
        name={name}
        bg='transparent'
        src={logo}
        ignoreFallback
      />
      <Text
        fontWeight='semibold'
        fontSize='md'
        whiteSpace='normal'
        color='gray.700'
        aria-selected={isSelected}
      >
        {name}
      </Text>
      <Spacer />
      <HStack>
        {isDefault && (
          <Tag size='sm' colorScheme='green' variant='subtle'>
            <TagLabel>Default</TagLabel>
          </Tag>
        )}
        <VisibleWithPermission permission={PERMISSIONS.organizationSupport}>
          <OrganizationStatusTag status={status} size='sm' />
        </VisibleWithPermission>
      </HStack>
      <Box w='8' pr={4}>
        {isSelected && <Icon color='purple.600' as={FaArrowRight} />}
      </Box>
    </HStack>
  );
};

const OrganizationChooserModal = createModal((props) => {
  const { modal } = props;

  const [query, setQuery] = React.useState('');
  const focusedRef = React.useRef<HTMLLIElement>(null);

  const navigate = useNavigate();
  const { user } = useAppContext();
  const defaultOrganizationId = user.settings.defaultOrganization;

  const { data, isLoading } = useOrganizationsQuery({});

  const organizations: SelectOption<Organization>[] = React.useMemo(() => {
    return (data?.organizations ?? []).map((organization) => ({
      label: organization.name,
      value: organization.id,
      raw: organization as Organization,
    }));
  }, [data?.organizations]);

  const filteredOrganizations = React.useMemo(() => {
    if (query.length < 2) {
      return organizations;
    }

    return matchSorter(organizations, query, {
      keys: ['label'],
    });
  }, [query, organizations]);

  const { focusedOption, focusFirstOption, focusPrevOption, focusNextOption, focusOption } =
    useOptionsFocus<Organization>(filteredOrganizations);

  const navigateToOrganization = React.useCallback(
    (organization: Organization) => {
      modal.hide();
      navigate(`/organizations/${organization.id}`);
    },
    [navigate, modal],
  );

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowDown': {
          e.preventDefault();

          focusNextOption();
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          focusPrevOption();
          break;
        }
        case 'Enter': {
          if (filteredOrganizations.length <= 0 || !focusedOption) {
            break;
          }

          navigateToOrganization(focusedOption.option.raw);
          break;
        }
      }
    },
    [
      filteredOrganizations,
      focusedOption,
      focusNextOption,
      focusPrevOption,
      navigateToOrganization,
    ],
  );

  React.useLayoutEffect(() => {
    focusFirstOption();
  }, [focusFirstOption]);

  React.useLayoutEffect(() => {
    if (focusedOption?.keyboard) {
      focusedRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focusedOption]);

  let content = null;

  if (isLoading) {
    content = (
      <Box h='full'>
        <Spin />
      </Box>
    );
  } else {
    content = (
      <ScrollAreaAutosize maxH='md' overflow='auto' py={2}>
        <List px={4} data-pw='organizationChooserMenu'>
          {filteredOrganizations.map((organization) => {
            const isFocused = focusedOption?.option.value === organization.value;
            const ref = isFocused ? focusedRef : undefined;

            return (
              <ListItem
                ref={ref}
                key={organization.value}
                bg='white'
                rounded='lg'
                px={2}
                py={1}
                my={2}
                data-pw={`organizationChooserItem-${organization.raw.id}`}
                aria-selected={isFocused ? true : undefined}
                onMouseEnter={() => focusOption(organization, false)}
                onClick={() => navigateToOrganization(organization.raw)}
                _selected={{
                  bg: 'gray.200',
                  mark: {
                    color: 'white',
                    textDecoration: 'underline',
                  },
                }}
              >
                <OrganizationItem
                  name={organization.raw.name}
                  logo={organization.raw.logo ?? undefined}
                  isSelected={isFocused}
                  isDefault={organization.raw.id === defaultOrganizationId}
                  status={organization.raw.status}
                />
              </ListItem>
            );
          })}
        </List>
      </ScrollAreaAutosize>
    );
  }

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader p={0} m={0}>
          <HStack py={2} px={6}>
            <Icon
              as={FaSearch}
              color='gray.300'
              _hover={{ color: 'gray.500' }}
              pointerEvents='none'
            />
            <Input
              variant='unstyled'
              size='lg'
              rounded='xl'
              placeholder='Switch organization'
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={onKeyDown}
              bg='transparent'
              _hover={{
                bg: 'transparent',
              }}
              _focus={{
                bg: 'transparent',
              }}
              _focusWithin={{
                bg: 'transparent',
              }}
            />

            <Box pointerEvents='none' fontSize='sm' color='gray.400' flexShrink={0}>
              <Kbd>Cmd/Ctrl</Kbd> + <Kbd>K</Kbd>
            </Box>
          </HStack>
        </ModalHeader>
        <ModalBody p={0}>
          <Box h='md'>{content}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export const OrganizationChooser = () => {
  const { config, organization, user } = useAppContext();

  const openModal = React.useCallback(() => {
    showModal(OrganizationChooserModal, {});
  }, []);

  React.useEffect(() => {
    const keys = ['super + k', 'ctrl + k'];
    keyboardJS.bind(keys, openModal);

    return () => {
      keyboardJS.unbind(keys, openModal);
    };
  }, [openModal]);

  const hasMultipleOrgs = user.organizationMemberships.length > 1;
  const suffix = config.organizationMenuSuffix ? ` - ${config.organizationMenuSuffix}` : '';
  const organizationName = `${organization.name}${suffix}`;
  const dropdownIcon = hasMultipleOrgs ? <Icon as={FaAngleDown} /> : undefined;

  return (
    <Button
      size='lg'
      variant='link'
      color='white'
      _active={{ color: 'white' }}
      leftIcon={<PiccoloIcon boxSize='6' />}
      rightIcon={dropdownIcon}
      data-pw='organizationChooserButton'
      onClick={openModal}
    >
      <Text display={{ sm: 'none', md: 'block' }}>{organizationName}</Text>
    </Button>
  );
};
