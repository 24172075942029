/* See https://github.com/sindresorhus/pretty-bytes/blob/main/index.js for the original source code. */

import { P } from '.';

interface LocaleOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const BYTE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const BIBYTE_UNITS = ['B', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

const BIT_UNITS = ['b', 'kbit', 'Mbit', 'Gbit', 'Tbit', 'Pbit', 'Ebit', 'Zbit', 'Ybit'];

const BIBIT_UNITS = ['b', 'kibit', 'Mibit', 'Gibit', 'Tibit', 'Pibit', 'Eibit', 'Zibit', 'Yibit'];

/*
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const formatToLocaleString = (number: any, locale: any, options: any) => {
  let result = number;

  if (typeof locale === 'string' || Array.isArray(locale)) {
    result = number.toLocaleString(locale, options);
  } else if (locale === true || options !== undefined) {
    result = number.toLocaleString(undefined, options);
  }

  return result;
};

export const prettyBytes = (value: number) => {
  if (!P.isNumberFinite(value)) {
    throw new TypeError(`Expected a finite number, got ${typeof value}: ${value}`);
  }

  let updatedNumber = value;
  const separator = ' ';

  const isNegative = updatedNumber < 0;
  const prefix = isNegative ? '-' : '';

  if (isNegative) {
    updatedNumber = -updatedNumber;
  }

  if (updatedNumber < 1) {
    return prefix + String(updatedNumber) + separator + BYTE_UNITS[0];
  }

  const exponent = Math.min(Math.floor(Math.log10(updatedNumber) / 3), BYTE_UNITS.length - 1);

  updatedNumber /= 1000 ** exponent;

  updatedNumber = Number(updatedNumber.toPrecision(3));

  const unit = BYTE_UNITS[exponent];

  return prefix + String(updatedNumber) + separator + unit;
};
