import { DateTime, P } from '@piccolohealth/util';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  onAuthStateChanged,
  onIdTokenChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signOut,
  TotpMultiFactorGenerator,
  type MultiFactorError,
  type MultiFactorResolver,
  type MultiFactorSession,
  type TotpSecret,
  type User,
} from 'firebase/auth';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useConfig } from './ConfigContext';

export type StsTokenManager = {
  accessToken: string;
  expirationTime: number;
  isExpired: boolean;
};

export type AccessTokenResponse = {
  token: string;
  isExpired: boolean;
  expirationTime: number;
  currentTime: number;
};

export interface PhoneVerifyChallenge {
  type: 'phone';
  resolver: MultiFactorResolver;
  verificationId: string;
}

export interface PhoneEnrolChallenge {
  type: 'phone';
  phoneNumber: string;
  session: MultiFactorSession;
  verificationId: string;
}

export interface TotpVerifyChallenge {
  type: 'totp';
  resolver: MultiFactorResolver;
}

export interface TotpEnrolChallenge {
  type: 'totp';
  secret: TotpSecret;
  uri: string;
}

export type MultiFactorVerifyChallenge = PhoneVerifyChallenge | TotpVerifyChallenge;

export interface AuthContextProps {
  user: User | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  isMFAEnrolled: boolean;
  isRecentLogin: boolean;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  getMultiFactorVerifyChallenge: (error: MultiFactorError) => Promise<MultiFactorVerifyChallenge>;
  sendPhoneVerificationCode: (resolver: MultiFactorResolver) => Promise<PhoneVerifyChallenge>;
  verifyPhoneVerificationCode: (
    challenge: PhoneVerifyChallenge,
    verificationCode: string,
  ) => Promise<void>;
  sendPhoneEnrolmentCode: (phone: string) => Promise<PhoneEnrolChallenge>;
  verifyPhoneEnrolmentCode: (
    challenge: PhoneEnrolChallenge,
    verificationCode: string,
  ) => Promise<void>;
  verifyTotpVerificationCode: (
    challenge: TotpVerifyChallenge,
    verificationCode: string,
  ) => Promise<void>;
  generateTotpEnrolCode: () => Promise<TotpEnrolChallenge>;
  verifyTotpEnrolmentCode: (
    challenge: TotpEnrolChallenge,
    verificationCode: string,
  ) => Promise<void>;
  getAccessToken: (forceRefresh?: boolean) => Promise<AccessTokenResponse>;
  requestResetPasswordEmail: (email: string) => Promise<void>;
  resetPassword: (oobCode: string, email: string, password: string) => Promise<void>;
  requestVerifyInviteEmail: (email: string) => Promise<void>;
  verifyInvite: (oobCode: string, email: string, password: string) => Promise<void>;
}

const INVALID_SESSION_THRESHOLD = 4 * 60 * 1000; // 4 minutes

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export const AuthContextProvider = (props: React.PropsWithChildren) => {
  const { config } = useConfig();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState<User | null>(null);
  const [isMFAEnrolled, setIsMFAEnrolled] = React.useState(false);

  const auth = React.useMemo(() => {
    const auth = getAuth(initializeApp(config.firebase));
    auth.settings.appVerificationDisabledForTesting = config.firebase.recaptchaDisabled;
    return auth;
  }, [config.firebase]);

  const isLoggedIn = React.useMemo(() => {
    return !!user;
  }, [user]);

  const isAuthenticated = React.useMemo(() => {
    return isLoggedIn && isMFAEnrolled;
  }, [isLoggedIn, isMFAEnrolled]);

  const isRecentLogin = React.useMemo(() => {
    if (!user?.metadata.lastSignInTime) {
      return false;
    }

    return (
      DateTime.fromRFC2822(user.metadata.lastSignInTime).diffNow('milliseconds').milliseconds >
      INVALID_SESSION_THRESHOLD
    );
  }, [user?.metadata.lastSignInTime]);

  React.useEffect(() => {
    const onUserChanged = async (user: User | null) => {
      setIsLoading(false);
      setUser(user);
      if (user) {
        const idToken = await user.getIdTokenResult();
        const requireMfa = idToken.claims.requireMfa ?? false;
        const isMfaEnrolled = multiFactor(user).enrolledFactors.length > 0;
        setIsMFAEnrolled(isMfaEnrolled || !requireMfa);
      }
    };

    const onAuthStateChangedUnsubscribe = onAuthStateChanged(auth, (user) => {
      onUserChanged(user).catch(console.error);
    });

    const idTokenChangedUnsubscribe = onIdTokenChanged(auth, (user) => {
      onUserChanged(user).catch(console.error);
    });

    return () => {
      onAuthStateChangedUnsubscribe();
      idTokenChangedUnsubscribe();
    };
  }, [auth]);

  const login = (email: string, password: string): Promise<User> => {
    return signInWithEmailAndPassword(auth, email, password).then(({ user }) => user);
  };

  const logout = (): Promise<void> => {
    return signOut(auth).then(() => {
      // clear user
      setUser(null);
      // clear react-query cache so that the user data is not cached
      queryClient.clear();
    });
  };

  const getAccessToken = async (forceRefresh?: boolean): Promise<AccessTokenResponse> => {
    if (!user) {
      throw new Error('User is not authenticated');
    }

    const token = await user.getIdToken(forceRefresh);

    const tokenManager: StsTokenManager = (user as any).stsTokenManager;

    return {
      token,
      currentTime: Date.now(), // same as what token manager does internally
      expirationTime: tokenManager.expirationTime,
      isExpired: tokenManager.isExpired,
    };
  };

  const getMultiFactorVerifyChallenge = async (
    error: MultiFactorError,
  ): Promise<MultiFactorVerifyChallenge> => {
    const resolver = getMultiFactorResolver(getAuth(), error);
    const factorId = resolver.hints[0].factorId;
    const type = factorId === 'totp' ? 'totp' : 'phone';

    switch (type) {
      case 'phone': {
        const challenge = await sendPhoneVerificationCode(resolver);
        return { ...challenge };
      }
      case 'totp': {
        return { type, resolver };
      }
    }
  };

  const sendPhoneVerificationCode = async (
    resolver: MultiFactorResolver,
  ): Promise<PhoneVerifyChallenge> => {
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const phoneOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneOptions,
      recaptchaVerifier,
    );

    // Clear the recaptcha verifier after the phone number is verified. Must recreate the recaptcha div
    recaptchaVerifier.clear();
    document.getElementById('recaptcha-container')!.innerHTML = '<div id="recaptcha"></div>';

    return { type: 'phone', resolver, verificationId };
  };

  const verifyPhoneVerificationCode = async (
    challenge: PhoneVerifyChallenge,
    verificationCode: string,
  ): Promise<void> => {
    const credential = PhoneAuthProvider.credential(challenge.verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

    return challenge.resolver.resolveSignIn(multiFactorAssertion).then(P.noop);
  };

  const sendPhoneEnrolmentCode = async (phoneNumber: string): Promise<PhoneEnrolChallenge> => {
    if (!user) {
      throw new Error('User is not logged in');
    }

    const session = await multiFactor(user).getSession();
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const phoneOptions = {
      phoneNumber,
      session,
    };

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneOptions,
      recaptchaVerifier,
    );

    // Clear the recaptcha verifier after the phone number is verified. Must recreate the recaptcha div
    recaptchaVerifier.clear();
    document.getElementById('recaptcha-container')!.innerHTML = '<div id="recaptcha"></div>';

    return { type: 'phone', phoneNumber, verificationId, session };
  };

  const verifyPhoneEnrolmentCode = async (
    challenge: PhoneEnrolChallenge,
    verificationCode: string,
  ): Promise<void> => {
    if (!user) {
      return logout();
    }

    const { verificationId } = challenge;

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    await multiFactor(user).enroll(multiFactorAssertion, 'Phone number');
    await getAccessToken(true);
  };

  const generateTotpEnrolCode = async (): Promise<TotpEnrolChallenge> => {
    if (!user || !user.email) {
      throw new Error('User is not logged in');
    }

    const multiFactorSession = await multiFactor(user).getSession();
    const secret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
    const uri = secret.generateQrCodeUrl(user.email, 'Piccolo Health');
    return { type: 'totp', uri, secret };
  };

  const verifyTotpEnrolmentCode = async (
    challenge: TotpEnrolChallenge,
    verificationCode: string,
  ): Promise<void> => {
    if (!user) {
      throw new Error('User is not logged in');
    }

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
      challenge.secret,
      verificationCode,
    );
    await multiFactor(user).enroll(multiFactorAssertion, 'TOTP');
    await getAccessToken(true);
  };

  const verifyTotpVerificationCode = async (
    challenge: TotpVerifyChallenge,
    verificationCode: string,
  ): Promise<void> => {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      challenge.resolver.hints[0].uid,
      verificationCode,
    );

    return await challenge.resolver.resolveSignIn(multiFactorAssertion).then(P.noop);
  };

  const requestResetPasswordEmail = async (email: string): Promise<void> => {
    await axios.post(`${config.api.url}/users/request-reset-password-email`, { email });
  };

  const resetPassword = async (oobCode: string, email: string, password: string): Promise<void> => {
    await axios.post(`${config.api.url}/users/reset-password`, { oobCode, email, password });
  };

  const requestVerifyInviteEmail = async (email: string): Promise<void> => {
    await axios.post(`${config.api.url}/users/request-verify-invite-email`, { email });
  };

  const verifyInvite = async (oobCode: string, email: string, password: string): Promise<void> => {
    await axios.post(`${config.api.url}/users/verify-invite`, { oobCode, email, password });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        isLoggedIn,
        isMFAEnrolled,
        isRecentLogin,
        isAuthenticated,
        login,
        logout,
        getAccessToken,
        getMultiFactorVerifyChallenge,
        sendPhoneVerificationCode,
        verifyPhoneVerificationCode,
        sendPhoneEnrolmentCode,
        verifyPhoneEnrolmentCode,
        verifyTotpVerificationCode,
        generateTotpEnrolCode,
        verifyTotpEnrolmentCode,
        requestResetPasswordEmail,
        resetPassword,
        requestVerifyInviteEmail,
        verifyInvite,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
