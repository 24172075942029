import { Box, Button, Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import { ImageUploader } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../../../hooks/useAppContext';
import { readPdfAsImage } from '../../../../../../../utils/pdf';
import { HookedFormItem } from '../../../../../../forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../../../../../../forms/hookform/HookedSubmitButton';
import { HookedTextArea } from '../../../../../../forms/hookform/HookedTextArea';
import { Divider } from '../../../../../../generic/Divider';
import type { CreateMediaAttachmentImageUploadRequest } from '../MediaAttachmentZone';

interface Props {
  onAttachImageUpload: (value: CreateMediaAttachmentImageUploadRequest) => void;
  onClose: () => void;
  onBack: () => void;
}

const MAX_WIDTH = 1280;
const MAX_HEIGHT = 720;
const MIN_RATIO = 2 / 4;
const MAX_RATIO = 4 / 2;

export const ImageUploadAttachmentForm = (props: Props) => {
  const { onAttachImageUpload, onClose, onBack } = props;

  const { errorToast } = useAppContext();

  const methods = useForm<CreateMediaAttachmentImageUploadRequest>({
    defaultValues: {
      id: uuid(),
      type: 'IMAGE_UPLOAD',
      description: null,
      data: null,
      metadata: null,
    },
  });

  const { setValue } = methods;

  const data = methods.watch('data');

  const setImageData = React.useCallback(
    (value: string | null) => {
      setValue('data', value, { shouldDirty: true });
    },
    [setValue],
  );

  const onSubmit = React.useCallback(
    (data: CreateMediaAttachmentImageUploadRequest) => {
      onAttachImageUpload(data);
      onClose();
    },
    [onAttachImageUpload, onClose],
  );

  const onError = React.useCallback(
    (error: Error) => {
      errorToast(error.message ?? error);
    },
    [errorToast],
  );

  const handleFileTypes = React.useMemo(
    () => ({
      'application/pdf': readPdfAsImage,
    }),
    [],
  );

  return (
    <FormProvider {...methods}>
      <Box as='form' onSubmit={methods.handleSubmit(onSubmit)} h='full'>
        <HStack h='full' spacing={4}>
          <ImageUploader
            maxWidth={MAX_WIDTH}
            maxHeight={MAX_HEIGHT}
            minRatio={MIN_RATIO}
            maxRatio={MAX_RATIO}
            value={data}
            onChange={setImageData}
            onError={onError}
            handleFileTypes={handleFileTypes}
          />
          <Spacer />
          <Stack spacing={4} h='full' w='3xs'>
            <Heading size='sm'>Attach a custom image or PDF</Heading>
            <HookedFormItem
              name='description'
              label='Description'
              helperText='A caption below your image'
            >
              <HookedTextArea name='description' minRows={3} />
            </HookedFormItem>
            <Divider />
            <HStack>
              <Button onClick={onBack}>Back</Button>
              <Button onClick={onClose}>Close</Button>
              <HookedSubmitButton data-pw='attachImageUploadSubmitButton'>Save</HookedSubmitButton>
            </HStack>
          </Stack>
        </HStack>
      </Box>
    </FormProvider>
  );
};
