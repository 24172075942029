import { useOutsideClick } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { dataAttr } from '../utils/dataAttr';
import { useOptionsFocus } from './useOptionsFocus';

export const getSelectedOptions = <A extends string>(
  options: SelectOption<A>[],
  value: A | A[] | undefined,
  capitalizeLabels?: boolean,
): SelectOption<A>[] => {
  const flattened: A[] = P.compact(P.flatten([value]));

  return flattened.map((v) => {
    const existingOption = options.find((o) => P.isEqual(o.value, v));

    // If value doesn't exist as an option, still display the value
    return (
      existingOption ?? {
        label: (capitalizeLabels ?? true) ? P.capitalize(v) : v,
        value: v,
        raw: v,
      }
    );
  });
};

export const getOptions = <A, B>(
  options: SelectOption<A>[] | SelectGroup<A, B>[],
): SelectOption<A>[] => {
  return options.flatMap((option) => {
    return 'options' in option ? option.options : [option];
  });
};

export interface SelectOption<A> {
  value: string;
  label: string;
  raw: A;
  color?: string;
  disabled?: boolean;
}

export interface SelectGroup<A, B> {
  id: string;
  label: string;
  raw: B;
  options: SelectOption<A>[];
}

export interface UseSelectOptions<A, B> {
  options: SelectOption<A>[] | SelectGroup<A, B>[];
  value: SelectOption<A> | null;
  inputValue?: string;
  isDisabled?: boolean;
  onChange: (req: SelectOption<A>) => void;
  onInputChange?: (value: string) => void;
  onOpen?: () => void;
}

export const useSelect = <A, B>(opts: UseSelectOptions<A, B>) => {
  const { options, value, inputValue, isDisabled, onChange, onInputChange, onOpen } = opts;

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValueInternal, setInputValueInternal] = React.useState<string>('');
  const inputValuePrime = inputValue ?? inputValueInternal;

  const filter = React.useCallback(
    (option: SelectOption<A>) => {
      if (P.isUndefined(inputValue)) {
        return option.label.toLowerCase().includes(inputValuePrime.toLowerCase());
      }
      return true;
    },
    [inputValuePrime, inputValue],
  );

  const filteredOptions: SelectOption<A>[] | SelectGroup<A, B>[] = React.useMemo(() => {
    if (inputValuePrime === '') {
      return options;
    }

    const groups: SelectGroup<A, B>[] = [];
    const items: SelectOption<A>[] = [];

    for (const option of options) {
      if ('options' in option) {
        groups.push({
          ...option,
          options: option.options.filter(filter),
        });
      } else {
        filter(option) && items.push(option);
      }
    }

    return P.isEmpty(groups) ? items : groups;
  }, [inputValuePrime, options, filter]);

  const filteredOs = React.useMemo(() => getOptions(options).filter(filter), [options, filter]);

  const { focusedOption, focusFirstOption, focusPrevOption, focusNextOption, focusOption } =
    useOptionsFocus<A>(filteredOs);
  const focusedRef = React.useRef<HTMLLIElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onInputChangePrime = React.useCallback(
    (value: string) => {
      onInputChange ? onInputChange(value) : setInputValueInternal(value);
    },
    [onInputChange],
  );

  const focusButton = React.useCallback(() => {
    buttonRef.current?.focus();
  }, []);

  const focusInput = React.useCallback(() => {
    // wait for the input to be rendered
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  }, []);

  const openMenu = React.useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      focusInput();
      focusFirstOption();
      onOpen?.();
    }
  }, [focusFirstOption, focusInput, isOpen, onOpen]);

  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
    onInputChangePrime('');
  }, [onInputChangePrime]);

  const toggleMenu = React.useCallback(() => {
    if (!isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [isOpen, openMenu, closeMenu]);

  const selectOption = React.useCallback(
    (selectedValue: SelectOption<A>) => {
      if (selectedValue.disabled) {
        return;
      }

      onChange(selectedValue);
      closeMenu();
      focusButton();
    },
    [onChange, closeMenu, focusButton],
  );

  const selectFocusedOption = React.useCallback(() => {
    if (focusedOption) {
      selectOption(focusedOption.option);
    }
  }, [focusedOption, selectOption]);

  const handleOpenKeyboardEvent = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        focusNextOption();
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        focusPrevOption();
      } else if (e.key === 'Enter') {
        e.preventDefault();
        selectFocusedOption();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        closeMenu();
      } else if (e.key === 'Tab') {
        closeMenu();
      } else {
        focusInput();
      }
    },
    [closeMenu, focusInput, focusNextOption, focusPrevOption, selectFocusedOption],
  );

  const menuProps = React.useCallback(() => {
    return {
      ref: menuRef,
      role: 'group',
    };
  }, []);

  const buttonProps = React.useCallback(() => {
    return {
      ref: buttonRef,
      tabIndex: isDisabled ? -1 : 0,
      role: 'group',

      onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        toggleMenu();
      },

      onFocus: (e: React.FocusEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        // if menuRef contains the relatedTarget, then we came from inside the menu
        // If we did not come from inside the menu, then we should open the menu
        const cameFromMenu = menuRef.current?.contains(e.relatedTarget);

        if (!cameFromMenu) {
          openMenu();
        }
      },

      onKeyDown: (e: React.KeyboardEvent) => {
        if (isOpen) {
          handleOpenKeyboardEvent(e);
        } else {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
            e.preventDefault();
            openMenu();
          }
        }
      },
    };
  }, [isDisabled, toggleMenu, openMenu, isOpen, handleOpenKeyboardEvent]);

  const inputProps = React.useCallback(() => {
    return {
      ref: inputRef,
      tabIndex: 0,
      value: inputValuePrime,

      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onInputChangePrime(e.target.value);
        focusFirstOption();
      },

      onKeyDown: (e: React.KeyboardEvent) => {
        if (isOpen) {
          handleOpenKeyboardEvent(e);
        }
      },
    };
  }, [inputValuePrime, isOpen, onInputChangePrime, focusFirstOption, handleOpenKeyboardEvent]);

  const optionProps = React.useCallback(
    (option: SelectOption<A>) => {
      const isDisabled = option.disabled;
      const isFocused = focusedOption?.option.value === option.value;
      const isSelected = value?.value === option.value;

      return {
        ref: isFocused ? focusedRef : undefined,
        role: 'group',
        disabled: isDisabled,
        'data-disabled': dataAttr(isDisabled),
        'data-active': dataAttr(isFocused),
        'data-checked': dataAttr(isSelected),
        'data-selected': dataAttr(isSelected),
        'data-label': option.label,
        'data-value': option.value,

        onMouseDown: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) {
            selectOption(option);
          }
        },

        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) {
            selectOption(option);
          }
        },

        onMouseEnter: () => {
          focusOption(option);
        },
      };
    },
    [focusedOption, value, selectOption, focusOption],
  );

  React.useLayoutEffect(() => {
    if (focusedOption?.keyboard) {
      focusedRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focusedOption]);

  useOutsideClick({
    enabled: isOpen,
    ref: menuRef,
    handler: closeMenu,
  });

  return {
    options: filteredOptions,
    isOpen,
    isSearching: true,
    value,
    focusedOption,
    selectOption,
    selectFocusedOption,
    focusNextOption,
    focusPrevOption,
    focusFirstOption,
    buttonProps,
    menuProps,
    optionProps,
    inputProps,
  };
};
