import { Input, Select, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';

export const GlobalsValues = ['inherit', 'initial', 'revert', 'revert-layer', 'unset'] as const;
export type Globals = (typeof GlobalsValues)[number];

export const DisplayValues = [
  ...GlobalsValues,
  'block',
  'flex',
  'grid',
  'inline',
  'inline-block',
  'inline-flex',
];
export type Display = (typeof DisplayValues)[number];

export const FlexDirValues = [...GlobalsValues, 'row', 'column'];
export type FlexDir = (typeof FlexDirValues)[number];

export const JustifyContentValues = [
  ...GlobalsValues,
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'space-evenly',
] as const;
export type JustifyContent = (typeof JustifyContentValues)[number];

export const AlignItemsValues = [
  ...GlobalsValues,
  'flex-start',
  'flex-end',
  'center',
  'stretch',
  'baseline',
] as const;
export type AlignItems = (typeof AlignItemsValues)[number];

export type Grid = string;

export interface NodeLayoutProps {
  display?: Display;
  flexDirection?: FlexDir;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  grid: Grid;
}

export const NodeLayoutSettings = () => {
  const {
    actions: { setProp },
    display,
    flexDirection,
    justifyContent,
    alignItems,
    grid,
  } = useNode<NodeLayoutProps>((node) => ({
    display: node.data.props.display,
    flexDirection: node.data.props.flexDirection,
    justifyContent: node.data.props.justifyContent,
    alignItems: node.data.props.alignItems,
    grid: node.data.props.grid,
  }));

  return (
    <SimpleGrid columns={2} spacing={4}>
      <FormItem id='display' label='Display'>
        <Select
          defaultValue={display}
          onChange={(e) =>
            setProp((props: NodeLayoutProps) => {
              props.display = e.target.value;
            })
          }
          size='sm'
        >
          {DisplayValues.map((value) => (
            <option key={value} value={value} label={value} />
          ))}
        </Select>
      </FormItem>
      <FormItem id='flexDirection' label='Flex direction'>
        <Select
          defaultValue={flexDirection}
          onChange={(e) =>
            setProp((props: NodeLayoutProps) => {
              props.flexDirection = e.target.value;
            })
          }
          size='sm'
        >
          {FlexDirValues.map((value) => (
            <option key={value} value={value} label={value} />
          ))}
        </Select>
      </FormItem>
      <FormItem id='justifyContent' label='Justify content'>
        <Select
          defaultValue={justifyContent}
          onChange={(e) =>
            setProp((props: NodeLayoutProps) => {
              props.justifyContent = e.target.value as NodeLayoutProps['justifyContent'];
            })
          }
          size='sm'
        >
          {JustifyContentValues.map((value) => (
            <option key={value} value={value} label={value} />
          ))}
        </Select>
      </FormItem>
      <FormItem id='alignItems' label='Align items'>
        <Select
          defaultValue={alignItems}
          onChange={(e) =>
            setProp((props: NodeLayoutProps) => {
              props.alignItems = e.target.value as NodeLayoutProps['alignItems'];
            })
          }
          size='sm'
        >
          {AlignItemsValues.map((value) => (
            <option key={value} value={value} label={value} />
          ))}
        </Select>
      </FormItem>
      <FormItem id='grid' label='Grid'>
        <Input
          defaultValue={grid}
          onChange={(e) =>
            setProp((props: NodeLayoutProps) => {
              props.grid = e.target.value;
            })
          }
          size='sm'
        />
      </FormItem>
    </SimpleGrid>
  );
};

NodeLayoutSettings.defaultProps = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'flex-start' as const,
  alignItems: 'stretch' as const,
  grid: '',
};
