import type { PiccoloError } from '@piccolohealth/pbs-common';
import { getRequestHeaders } from '@piccolohealth/util';
import axios, { type AxiosRequestConfig } from 'axios';
import { useAuth } from '../context/AuthContext';
import { useConfig } from '../context/ConfigContext';
import { asPiccoloError } from '../utils/errors';
import React from 'react';

export const useAxios = () => {
  const { config } = useConfig();
  const { getAccessToken } = useAuth();

  const axiosRequest = React.useCallback((requestConfig: AxiosRequestConfig) => {
    return axios(requestConfig).catch((error) => {
      throw asPiccoloError(error);
    });
  }, []);

  const getAxiosHeaders = React.useCallback(
    async (headers: AxiosRequestConfig['headers'], forceAuthRefresh?: boolean) => {
      const accessToken = await getAccessToken(forceAuthRefresh);

      return {
        ...getRequestHeaders({
          accessToken,
          commit: config.buildInfo.commit,
        }),
        ...headers,
      };
    },
    [config.buildInfo.commit, getAccessToken],
  );

  return React.useCallback(
    async (requestConfig: AxiosRequestConfig) => {
      return axiosRequest({
        ...requestConfig,
        headers: await getAxiosHeaders(requestConfig.headers ?? {}),
      }).catch(async (error: PiccoloError) => {
        if (error.type === 'AuthUnableToVerifyToken') {
          return axiosRequest({
            ...requestConfig,
            headers: await getAxiosHeaders(requestConfig.headers ?? {}, true),
          });
        }

        throw error;
      });
    },
    [axiosRequest, getAxiosHeaders],
  );
};
