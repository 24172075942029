import * as J from 'json-decoder';
import { mapValues } from './p';

export type { Decoder, Err, Ok } from 'json-decoder';
export {
  allOfDecoders,
  anyDecoder,
  arrayDecoder,
  boolDecoder,
  decoder,
  ERR,
  err,
  exactDecoder,
  nullDecoder,
  numberDecoder,
  OK,
  objectDecoder,
  ok,
  stringDecoder,
  undefinedDecoder,
  valueDecoder,
} from 'json-decoder';

export type ArrayType<A> = A extends Array<infer T> ? T : never;

export const withDefault = <A>(decoder: J.Decoder<A>, defaultValue: A): J.Decoder<A> => {
  return J.oneOfDecoders(decoder, J.valueDecoder(defaultValue));
};

export const withNullDefault = <A>(decoder: J.Decoder<A>): J.Decoder<A | null> => {
  return withDefault(decoder, null);
};

export const numberLikeDecoder: J.Decoder<number> = J.oneOfDecoders(
  J.numberDecoder,
  J.stringDecoder.map(Number.parseFloat),
);

export const oneOfDecoders = <T>(...decoders: J.Decoder<T>[]): ArrayType<typeof decoders> =>
  J.decoder((a: unknown) => {
    for (const decoderTry of decoders) {
      const result = decoderTry.decode(a);
      if (result.type === J.OK) return J.ok(result.value);
    }
    return J.err(`one of: none of decoders match for object ${JSON.stringify(a)}`);
  }) as ArrayType<typeof decoders>;

/**
 * Array decoder, but allows for partial decoding of the array. If an item in the array
 * fails to decode, it will be skipped, rather than erroring
 */
export const partialArrayDecoder = <T>(itemDecoder: J.Decoder<T>): J.Decoder<T[]> =>
  J.decoder((a) => {
    if (Array.isArray(a)) {
      const res: T[] = [];

      for (const [_index, item] of a.entries()) {
        const itemResult = itemDecoder.decode(item);
        if (itemResult.type === J.OK) {
          res.push(itemResult.value);
        }
      }
      return J.ok(res);
    } else return J.err(`expected array, got ${typeof a}`);
  });

export const isAllOkObject = <T>(
  object: {
    [K in keyof T]: J.Result<T[K]>;
  },
): object is {
  [K in keyof T]: J.Ok<T[K]>;
} => {
  return Object.values<J.Result<T[keyof T]>>(object).every((result) => result.type === 'OK');
};

export const runDecoder = <A, B>(decoder: J.Decoder<B>, input: A): B => {
  const res = decoder.decode(input);

  switch (res.type) {
    case J.OK:
      return res.value;
    case J.ERR:
      throw new Error(res.message);
  }
};

type DecoderObject<T> = {
  [K in keyof T]: J.Decoder<T[K]>;
};

export type Result<T> = {
  type: typeof J.OK | typeof J.ERR;
  value: T;
  failures: { field: string; message: string }[];
};

// Useful when want to display decode result for each item in an object, rather
// then Decoding.objectDecoder which will stop on first error.
export const decodeObject = <T extends Record<string, unknown>>(
  data: unknown,
  object: DecoderObject<T>,
): Result<T> => {
  const decoded = mapValues(object, (decoder) => decoder.decode(data));

  const failures: { field: string; message: string }[] = [];
  const value = mapValues(decoded, (value, key) => {
    switch (value.type) {
      case J.OK: {
        return value.value;
      }

      case J.ERR: {
        failures.push({ field: key, message: value.message });
        return null;
      }
    }
  }) as T;

  return { type: J.ERR, value, failures };
};
