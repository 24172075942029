import { P } from '@piccolohealth/util';

export enum Layout {
  layout1x1 = '1x1',
  layout1x2 = '1x2',
  layout2x1 = '2x1',
  layout2x2 = '2x2',
}

/*
 * Returns rows and cols for a layout
 */
export const layoutToDimensions = (layout: Layout) => {
  switch (layout) {
    case Layout.layout1x1:
      return [1, 1];
    case Layout.layout1x2:
      return [1, 2];
    case Layout.layout2x1:
      return [2, 1];
    case Layout.layout2x2:
      return [2, 2];
  }
};

export const layoutToIndexes = (layout: Layout) => {
  const [rows, cols] = layoutToDimensions(layout);
  return P.range(0, rows * cols);
};

export const layoutToStyle = (layout: Layout) => {
  const [rows, cols] = layoutToDimensions(layout);
  const rowsRatio = 100 / rows;
  const colsRatio = 100 / cols;

  return {
    templateRows: `repeat(${rows}, ${rowsRatio}%)`,
    templateColumns: `repeat(${cols}, ${colsRatio}%)`,
  };
};

export const layoutToCount = (layout: Layout) => {
  const [rows, cols] = layoutToDimensions(layout);
  return rows * cols;
};

export const layoutInclusiveSelect = (layout: Layout): Record<Layout, boolean> => {
  return {
    [Layout.layout1x1]: true,
    [Layout.layout1x2]: [Layout.layout1x2, Layout.layout2x2].includes(layout),
    [Layout.layout2x1]: [Layout.layout2x1, Layout.layout2x2].includes(layout),
    [Layout.layout2x2]: layout === Layout.layout2x2,
  };
};

export const layoutToChooserColors = (
  currentLayout: Layout,
  hoveredLayout?: Layout,
): Record<Layout, string> => {
  const isCurrent = layoutInclusiveSelect(currentLayout);
  const isHovered = hoveredLayout
    ? layoutInclusiveSelect(hoveredLayout)
    : {
        [Layout.layout1x1]: false,
        [Layout.layout1x2]: false,
        [Layout.layout2x1]: false,
        [Layout.layout2x2]: false,
      };
  return P.mapValues(isHovered, (l, k) =>
    l ? 'purple.300' : P.get(isCurrent, k) ? 'purple.500' : 'purple.800',
  );
};
