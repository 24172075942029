import { ChakraProvider as V2ChakraProvider } from '@chakra-ui/react';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { theme } from './theme';

const V3System = ChakraV3.createSystem(
  ChakraV3.defaultConfig,
  ChakraV3.defineConfig({
    cssVarsPrefix: 'v3',
    globalCss: {
      '*': {
        fontFeatureSettings: 'normal',
      },
      body: {
        colorPalette: 'purple',
      },
    },
    theme: {
      semanticTokens: {
        colors: {
          bg: {
            navbar: {
              description: 'Color used for the navbar',
              value: '#00152A',
            },
          },
        },
        shadows: {
          popover: {
            description: 'Shadow used primarily for popovers',
            value:
              'rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px',
          },
        },
      },
    },
  }),
);

export const ChakraV2Provider = (props: React.PropsWithChildren) => {
  return (
    <V2ChakraProvider theme={theme} resetCSS={false}>
      {props.children}
    </V2ChakraProvider>
  );
};

export const ChakraV3Provider = (props: React.PropsWithChildren) => {
  return <ChakraV3.ChakraProvider value={V3System}>{props.children}</ChakraV3.ChakraProvider>;
};

export const ChakraProvider = (props: React.PropsWithChildren) => {
  return (
    <ChakraV3Provider>
      <ChakraV2Provider>{props.children}</ChakraV2Provider>
    </ChakraV3Provider>
  );
};
