import {
  Center,
  Checkbox,
  type CheckboxProps,
  HStack,
  Spacer,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  type TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { DndKitCore, DndKitSortable } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import {
  type ColumnDef,
  type OnChangeFn,
  type Table as RTable,
  type Row,
  type RowSelectionState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { DragHandle } from './DragHandle';
import { Empty } from './Empty';
import { Pagination, type PaginationOptions, type TimestampPaginationOptions } from './Pagination';
import { Sortable } from './Sortable';
import { Spin } from './Spin';

export { createColumnHelper } from '@tanstack/react-table';
export type { Column, ColumnDef, Row } from '@tanstack/react-table';

export interface RowSelectionOptions<TData> {
  selectedRows: TData[];
  onRowsSelect: (selectedRows: TData[]) => void;
  enableRowSelection?: (row: Row<TData>) => boolean;
}

export interface RowSortableOptions {
  onRowMove: (from: number, to: number) => void;
}

const DragHandleCell = <TData,>(props: { row: Row<TData> }) => {
  const { listeners, attributes } = DndKitSortable.useSortable({ id: props.row.id });
  return <DragHandle listeners={listeners} attributes={attributes} size='xs' />;
};

const CheckboxHeader = <TData,>(props: { table: RTable<TData> } & CheckboxProps) => {
  const { table, ...rest } = props;
  return (
    <Stack h='full' align='center' flexDir='row'>
      <Checkbox
        isChecked={table.getIsAllPageRowsSelected()}
        isIndeterminate={table.getIsSomePageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
        data-pw='tableCheckboxAll'
        {...rest}
      />
    </Stack>
  );
};

const CheckboxCell = <TData,>(props: { row: Row<TData> } & CheckboxProps) => {
  const { row, ...rest } = props;
  return (
    <Stack h='full' align='center' flexDir='row'>
      <Checkbox
        isChecked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
        {...rest}
      />
    </Stack>
  );
};

const SortableTr = <TData,>(props: { row: Row<TData>; isLastRow: boolean }) => {
  const { row, isLastRow } = props;

  const { setNodeRef, transform, transition } = DndKitSortable.useSortable({
    id: row.id,
  });

  return (
    <Sortable as={Tr} ref={setNodeRef} transform={transform} transition={transition} display='flex'>
      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          display='flex'
          flex={`${cell.column.columnDef.size} 1 0%`}
          maxW={`${cell.column.columnDef.maxSize}px`}
          minW={`${cell.column.columnDef.minSize}px`}
          alignItems='center'
          whiteSpace='break-spaces'
          border={isLastRow ? 'none' : undefined}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Sortable>
  );
};

interface Props<TData, TValue, TError> extends TableProps {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isLoading?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  error?: TError | null;
  pagination?: PaginationOptions | TimestampPaginationOptions;
  rowSelection?: RowSelectionOptions<TData>;
  rowSortable?: RowSortableOptions;
  getRowId?: (row: TData) => string;
  renderEmpty?: () => React.ReactElement;
  renderError?: (error: TError) => React.ReactElement;
}

export const DataTable = <TData, TValue, TError>(props: Props<TData, TValue, TError>) => {
  const {
    columns,
    data,
    isLoading,
    showHeader = true,
    showFooter = true,
    error,
    pagination,
    rowSortable,
    rowSelection,
    getRowId,
    renderEmpty,
    renderError,
    ...rest
  } = props;

  const rowSelectionPrime = React.useMemo(() => {
    if (!rowSelection) {
      return undefined;
    }

    const selectedRows = (rowSelection?.selectedRows ?? []).reduce<Record<number, boolean>>(
      (prev, curr) => {
        const index = data.findIndex((r) => r === curr);
        prev[index] = true;
        return prev;
      },
      {},
    );

    const onRowsSelect = (value: (old: RowSelectionState) => RowSelectionState): void => {
      const selectedIndexes = Object.keys(value(selectedRows));
      const newSelectedRows = P.compact(
        selectedIndexes.map((index) => data.at(Number.parseInt(index))),
      );
      rowSelection?.onRowsSelect(newSelectedRows);
    };

    return {
      selectedRows,
      onRowsSelect: onRowsSelect as OnChangeFn<RowSelectionState>,
      enableRowSelection: rowSelection?.enableRowSelection,
    };
  }, [data, rowSelection]);

  const isRowSelectionEnabled = React.useMemo(() => {
    return !P.isNil(rowSelection);
  }, [rowSelection]);

  const isRowSortableEnabled = React.useMemo(() => {
    return !P.isNil(rowSortable);
  }, [rowSortable]);

  const cs: ColumnDef<TData, TValue>[] = React.useMemo(() => {
    const allColumns: ColumnDef<TData, TValue>[] = [];

    if (isRowSelectionEnabled) {
      allColumns.push({
        id: 'select',
        header: ({ table }) => <CheckboxHeader table={table} size={rest.size} />,
        cell: ({ row }) => <CheckboxCell row={row} size={rest.size} />,
        size: 32,
        minSize: 32,
        maxSize: 32,
        enableSorting: false,
        enableHiding: false,
      });
    }

    if (isRowSortableEnabled) {
      allColumns.push({
        id: 'drag',
        size: 50,
        minSize: 50,
        maxSize: 50,
        cell: DragHandleCell,
      });
    }

    allColumns.push(...columns);

    return allColumns;
  }, [isRowSelectionEnabled, isRowSortableEnabled, columns, rest.size]);

  const table = useReactTable({
    data,
    columns: cs,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: rowSelectionPrime?.onRowsSelect,
    enableRowSelection: rowSelectionPrime?.enableRowSelection,
    state: {
      rowSelection: rowSelectionPrime?.selectedRows,
    },
    defaultColumn: {
      size: 150,
      minSize: 0,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const rows = table.getRowModel().rows;
  const { setRowSelection } = table;

  // https://github.com/biomejs/biome/issues/630
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run this effect only when data changes
  React.useEffect(() => {
    setRowSelection(() => ({}));
  }, [data, setRowSelection]);

  const onDragEnd = React.useCallback(
    (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = rows.findIndex((r) => r.id === active.id);
        const newIndex = rows.findIndex((r) => r.id === over.id);
        rowSortable?.onRowMove(oldIndex, newIndex);
      }
    },
    [rowSortable, rows],
  );

  const tableHeaderContent = (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id} display='flex'>
          {headerGroup.headers.map((header) => {
            return (
              <Th
                key={header.id}
                flex={`${header.column.columnDef.size} 1 0%`}
                maxW={`${header.column.columnDef.maxSize}px`}
                minW={`${header.column.columnDef.minSize}px`}
                alignItems='center'
                py={2}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </Th>
            );
          })}
        </Tr>
      ))}
    </>
  );

  const tableBodyContent = P.run(() => {
    if (isLoading) {
      return (
        <Tr>
          <Td>
            <Center py={8}>
              <Spin />
            </Center>
          </Td>
        </Tr>
      );
    }

    if (table.getRowModel().rows.length === 0) {
      return (
        <Tr>
          <Td>{renderEmpty ? renderEmpty() : <Empty />}</Td>
        </Tr>
      );
    }

    if (error) {
      return (
        <Tr>
          <Td>{renderError ? renderError(error) : <Empty />}</Td>
        </Tr>
      );
    }

    return (
      <DndKitCore.DndContext
        accessibility={{ container: document.body }}
        sensors={sensors}
        collisionDetection={DndKitCore.closestCenter}
        onDragEnd={onDragEnd}
      >
        <DndKitSortable.SortableContext
          strategy={DndKitSortable.verticalListSortingStrategy}
          items={rows.map((row) => row.id)}
        >
          {rows.map((row) => (
            <SortableTr key={row.id} row={row} isLastRow={row.index === rows.length - 1} />
          ))}
        </DndKitSortable.SortableContext>
      </DndKitCore.DndContext>
    );
  });

  const tableCaptionContent = P.run(() => {
    if (isLoading || error) {
      return null;
    }

    return (
      <HStack>
        {rowSelectionPrime && (
          <Text color='secondary'>
            {table.getFilteredSelectedRowModel().rows.length} of{' '}
            {table.getFilteredRowModel().rows.length} row(s) selected.
          </Text>
        )}
        <Spacer />
        {pagination && <Pagination {...pagination} />}
      </HStack>
    );
  });

  return (
    <TableContainer w='full'>
      <Table {...rest}>
        {showHeader && <Thead>{tableHeaderContent}</Thead>}
        <Tbody>{tableBodyContent}</Tbody>
        {showFooter && <TableCaption>{tableCaptionContent}</TableCaption>}
      </Table>
    </TableContainer>
  );
};
