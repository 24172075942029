import { Box, Button, Heading, Icon, VStack } from '@chakra-ui/react';
import { Element, useEditor } from '@craftjs/core';
import { uuid } from '@piccolohealth/echo-common';
import React from 'react';
import { FaClipboardList, FaImage, FaMinus, FaTable, FaTextHeight } from 'react-icons/fa';
import { Divider as ChakraDivider } from '../../generic/Divider';
import { CheckboxGroup } from './components/CheckboxGroup';
import { Column } from './components/Column';
import { Divider } from './components/Divider';
import { Image } from './components/Image';
import { Text } from './components/Text';

export type ConnectorsInterface = ReturnType<typeof useEditor>['connectors'];

export interface ToolboxComponent {
  name: string;
  icon?: React.ReactElement;
  create: (connectors: ConnectorsInterface, ref: HTMLElement) => HTMLElement;
}

interface Props {
  componentGroups: {
    name: string;
    components: ToolboxComponent[];
  }[];
}

export const components = {
  text: {
    name: 'Text',
    icon: <Icon as={FaTextHeight} />,
    create: (connectors: ConnectorsInterface, ref: HTMLElement) => connectors.create(ref, <Text />),
  },
  divider: {
    name: 'Divider',
    icon: <Icon as={FaMinus} />,
    create: (connectors: ConnectorsInterface, ref: HTMLElement) =>
      connectors.create(ref, <Divider />),
  },
  column: {
    name: 'Column',
    icon: <Icon as={FaTable} />,
    create: (connectors: ConnectorsInterface, ref: HTMLElement) =>
      connectors.create(ref, <Element canvas is={Column} {...Column.defaultProps} />),
  },
  image: {
    name: 'Image',
    icon: <Icon as={FaImage} />,
    create: (connectors: ConnectorsInterface, ref: HTMLElement) =>
      connectors.create(ref, <Image />),
  },
  checkboxGroup: {
    name: 'CheckboxGroup',
    icon: <Icon as={FaClipboardList} />,
    create: (connectors: ConnectorsInterface, ref: HTMLElement) =>
      connectors.create(ref, <CheckboxGroup />),
  },
};

export const DraggableComponent = (props: { component: ToolboxComponent }) => {
  const { connectors } = useEditor();
  const { component } = props;
  const [key, setKey] = React.useState(uuid());

  return (
    <Button
      key={key}
      size='sm'
      leftIcon={component.icon}
      variant='outline'
      w='100%'
      onDragEnd={() => setKey(uuid())}
      ref={(ref) => (ref ? component.create(connectors, ref) : null)}
    >
      {component.name}
    </Button>
  );
};

export const Toolbox = (props: Props) => {
  const { componentGroups } = props;

  const contents = componentGroups.map((componentGroup) => {
    return (
      <Box key={componentGroup.name}>
        <Heading as='h5' size='sm' pb='2'>
          {componentGroup.name}
        </Heading>
        <VStack w='100%'>
          {componentGroup.components.map((component) => (
            <DraggableComponent key={component.name} component={component} />
          ))}
        </VStack>

        <ChakraDivider />
      </Box>
    );
  });

  return <Box>{contents}</Box>;
};
