import { Button, Icon, Menu, MenuButton, MenuList, Stack, Text } from '@chakra-ui/react';
import type { PiccoloError, ReportTemplate } from '@piccolohealth/echo-common';
import {
  createColumnHelper,
  DataTable,
  FancyDate,
  type PaginationOptions,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { TemplateRestoreVersionMenuItem } from './TemplateRestoreVersionControl';

interface Props {
  versions: ReportTemplate[];
  isLoading: boolean;
  error: PiccoloError | null;
}

export const VersioningTable = (props: Props) => {
  const { versions, isLoading, error } = props;

  const [paginationState, setPaginationState] = React.useState({
    currentPage: 0,
    pageSize: 10,
  });

  const { total, templateVersions } = React.useMemo(() => {
    const templateVersions = versions.slice(
      paginationState.currentPage * paginationState.pageSize,
      paginationState.currentPage * paginationState.pageSize + paginationState.pageSize,
    );

    return {
      total: versions.length,
      templateVersions: templateVersions,
    };
  }, [paginationState, versions]);

  const nextPage = React.useCallback(() => {
    setPaginationState((oldPaginationState) => ({
      ...oldPaginationState,
      currentPage: oldPaginationState.currentPage + 1,
    }));
  }, []);

  const previousPage = React.useCallback(() => {
    setPaginationState((oldPaginationState) => ({
      ...oldPaginationState,
      currentPage: oldPaginationState.currentPage - 1,
    }));
  }, []);

  const onPageSizeChange = React.useCallback((pageSize: number) => {
    setPaginationState((old) => ({
      ...old,
      pageSize,
    }));
  }, []);

  const hasNextPage = paginationState.currentPage < Math.ceil(total / paginationState.pageSize) - 1;
  const hasPreviousPage = paginationState.currentPage > 0;

  const pagination: PaginationOptions = {
    currentPage: paginationState.currentPage,
    pageSize: paginationState.pageSize,
    total,
    hasNextPage,
    hasPreviousPage,
    pageSizeOptions: [10, 20, 30],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} variables`,
    nextPage,
    previousPage,
    onPageSizeChange,
  };

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ReportTemplate>();

    return [
      columnHelper.display({
        header: 'Version',
        cell: (ps) => {
          return (
            <Stack spacing={1}>
              <Text fontWeight='bold' color='purple.600'>
                {ps.row.original.versionId}
              </Text>
            </Stack>
          );
        },
        minSize: 320,
      }),
      columnHelper.display({
        header: 'Date created',
        cell: (ps) => (
          <FancyDate date={DateTime.fromISO(ps.row.original.createdAt as unknown as string)} />
        ),
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (ps) => (
          <Menu>
            <MenuList>
              <TemplateRestoreVersionMenuItem version={ps.row.original} />
            </MenuList>
            <MenuButton
              as={Button}
              variant='outline'
              size='sm'
              rightIcon={<Icon as={FaAngleDown} />}
              data-pw={`reportTemplateVersionHistoryActionButton-${ps.row.original.versionId}`}
            >
              Actions
            </MenuButton>
          </Menu>
        ),
      }),
    ];
  }, []);

  return (
    <DataTable
      columns={columns}
      data={templateVersions}
      pagination={pagination}
      error={error}
      isLoading={isLoading}
      size='sm'
    />
  );
};
