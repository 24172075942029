import { HStack, Input, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Select, type SelectComponents, type SelectOption } from './Select';
import type { InputProps } from '@chakra-ui/react';

type CountryCodeMeta = {
  code: string;
  name: string;
  flag: string;
  callingCode: string;
};

const COUNTRY_CODES: CountryCodeMeta[] = [
  { code: 'AU', name: 'Australia', flag: '🇦🇺', callingCode: '+61' },
  { code: 'NZ', name: 'New Zealand', flag: '🇳🇿', callingCode: '+64' },
  { code: 'GB', name: 'United Kingdom', flag: '🇬🇧', callingCode: '+44' },
  { code: 'US', name: 'United States', flag: '🇺🇸', callingCode: '+1' },
];

const COUNTRY_CODE_OPTIONS: SelectOption<CountryCodeMeta>[] = COUNTRY_CODES.map((country) => ({
  label: country.name,
  value: country.code,
  raw: country,
}));

const COUNTRY_CODE_SELECT_COMPONENTS: SelectComponents<CountryCodeMeta, CountryCodeMeta> = {
  Value: (props) => {
    return (
      <Text fontSize='24px' lineHeight='0'>
        {props.option.raw.flag}
      </Text>
    );
  },
  Option: (props) => {
    return (
      <HStack>
        <Text fontSize='22px' lineHeight='0'>
          {props.option.raw.flag}
        </Text>
        <Text>{props.option.label}</Text>
      </HStack>
    );
  },
};

export interface HookedPhoneNumberInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

export const PhoneNumberInput = (props: HookedPhoneNumberInputProps) => {
  const { value, onChange, ...rest } = props;

  const [countryCode, setCountryCode] = React.useState<string>(() => {
    const possibleCountryCode = COUNTRY_CODES.find((country) =>
      value.startsWith(country.callingCode),
    );
    return possibleCountryCode ? possibleCountryCode.code : 'AU';
  });

  const selectedCountryCode: SelectOption<CountryCodeMeta> | null = React.useMemo(() => {
    return COUNTRY_CODE_OPTIONS.find((option) => option.value === countryCode) ?? null;
  }, [countryCode]);

  const onCountryCodeChange = (option: SelectOption<CountryCodeMeta>) => {
    setCountryCode(option.raw.code);
    onChange(`${option.raw.callingCode} `);
  };

  const onInputChange = (newValue: string) => {
    const possibleCountryCode = COUNTRY_CODES.find((country) =>
      newValue.startsWith(country.callingCode),
    );

    if (possibleCountryCode) {
      setCountryCode(possibleCountryCode.code);
    }

    onChange(newValue);
  };

  return (
    <Stack>
      <HStack>
        <Select
          size='md'
          w='56px'
          components={COUNTRY_CODE_SELECT_COMPONENTS}
          options={COUNTRY_CODE_OPTIONS}
          value={selectedCountryCode}
          onChange={onCountryCodeChange}
        />
        <Input
          w='full'
          size='md'
          type='tel'
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          placeholder='Phone number'
          {...rest}
        />
      </HStack>
    </Stack>
  );
};
