import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InviteUserRequest } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useInviteUserMutation } from '../../graphql/hooks/useInviteUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { FormSection } from '../forms/FormSection';
import { HookedFormItem } from '../forms/hookform/HookedFormItem';
import { HookedInput } from '../forms/hookform/HookedInput';
import { HookedSubmitButton } from '../forms/hookform/HookedSubmitButton';
import { createModal } from '../generic/Modal';
import { UserRolesSelectionTable } from '../settings/users/UserRolesSelectionTable';

interface FormValues {
  name: string;
  email: string;
  roleIds: string[];
}

export const UserInviteModal = createModal((props) => {
  const { modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const initialValues = { name: '', email: '', roleIds: [] };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    roleIds: Yup.array().min(1, 'At least one role required').required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useInviteUserMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    const inviteUserRequest = {
      name: values.name,
      email: values.email,
      roleIds: values.roleIds,
    } as InviteUserRequest;

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        inviteUserRequest,
      })
      .then(() => {
        successToast('User invited successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error inviting user: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-pw='userInviteModalHeader'>Invite user</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <Text mb={2} data-pw='userInviteModalDescription'>
                Invite a new member to join your organization
              </Text>
              <FormSection>
                <HookedFormItem label='Name' name='name' data-pw='userInviteModalNameFormItem'>
                  <HookedInput name='name' data-pw='userInviteModalNameInput' />
                </HookedFormItem>
                <HookedFormItem label='Email' name='email' data-pw='userInviteModalEmailFormItem'>
                  <HookedInput name='email' data-pw='userInviteModalEmailInput' />
                </HookedFormItem>
                <HookedFormItem label='Roles' name='roleIds' data-pw='userInviteModalRolesFormItem'>
                  <UserRolesSelectionTable name='roleIds' />
                </HookedFormItem>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button onClick={modal.hide}>Close</Button>
                <HookedSubmitButton data-pw='userInviteModalSubmitButton'>
                  Invite
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
