import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { type Distribution, DistributionStatus } from '@piccolohealth/echo-common';
import { Empty } from '@piccolohealth/ui';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { createModal } from '../../../components/generic/Modal';
import { PiccoloError } from '../../../components/generic/PiccoloError';
import { useReportDistributionsQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { DistributionSendCard } from './DistributionSendCard';

interface Props {
  reportId: string;
}

export const DistributionSendModal = createModal<Props>((props) => {
  const { reportId, modal } = props;
  const { organization } = useAppContext();
  const { hide, visible } = modal;

  const { isLoading, data, error } = useReportDistributionsQuery({
    organizationId: organization.id,
    reportId,
  });

  const distributions = (data?.organization?.report?.distributions ?? []) as Distribution[];

  const filteredDistributions = distributions.filter(
    (distribution) => distribution.status !== DistributionStatus.Sent,
  );

  const content: React.JSX.Element | undefined = P.run(() => {
    if (isLoading) {
      return <Spin />;
    } else if (error) {
      return <PiccoloError error={error} />;
    } else if (P.isEmpty(filteredDistributions)) {
      return <Empty title='No distributions that need sending' />;
    }
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Which distributions do you want to send?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {content}
          <Stack spacing={8}>
            {filteredDistributions.map((distribution) => (
              <DistributionSendCard key={distribution.id} distribution={distribution} />
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
