import { DateTime, type LooseObject, P } from '@piccolohealth/util';
import {
  type ReportChoiceVariable,
  type ReportMediaAttachmentVariable,
  type ReportStatement,
  type ReportStatementSite,
  type ReportStaticVariable,
  ReportStatus,
  type ReportTemplate,
  type ReportTemplateChoiceVariable,
  type ReportTemplateMediaAttachmentVariable,
  type ReportTemplateStatement,
  type ReportTemplateStatementSite,
  type ReportTemplateStaticVariable,
  ReportTemplateStatus,
  type ReportTemplateVariable,
  type ReportTemplateWallMotionVariable,
  type ReportVariable,
  type ReportWallMotionVariable,
  VariableControlType,
} from '../graphql/types';
import { uuid } from './generic';
import { compileHandlebarsTemplate, convertHandlebarsTemplateToTiptap } from './handlebars';
import { getWallMotionModule, renderWallMotion } from './wallmotion';

export interface ReportFormValues {
  statementSites: {
    [name: string]: ReportStatementSite;
  };
  variables: {
    [variableId: string]: ReportVariable;
  };
}

export interface ExportedReportTemplate {
  id: string;
  name: string;
  statementSites: ReportTemplateStatementSite[];
  variables: ReportTemplateVariable[];
  reportNodes: LooseObject;
}

export const renderNullable = (value: Array<any> | string | null | undefined): string => {
  const placeholder = '-';

  if (P.isArray(value)) {
    const sanitizedValue = P.compact(value);

    return !P.isEmpty(sanitizedValue) ? sanitizedValue.join(', ') : placeholder;
  } else if (P.isString(value)) {
    return value;
  } else {
    return placeholder;
  }
};

export const renderReportStatus = (status: ReportStatus): string => {
  switch (status) {
    case ReportStatus.Unreported:
      return 'Unreported';
    case ReportStatus.AwaitingReview:
      return 'Awaiting Review';
    case ReportStatus.Finalized:
      return 'Finalized';
  }
};

export const renderReportTemplateStatus = (status: ReportTemplateStatus): string => {
  switch (status) {
    case ReportTemplateStatus.Archived:
      return 'Archived';
    case ReportTemplateStatus.Draft:
      return 'Draft';
    case ReportTemplateStatus.Published:
      return 'Published';
  }
};

export const renderReportTemplateVariableType = (
  type: ReportTemplateVariable['__typename'],
): string => {
  switch (type) {
    case 'ReportTemplateStaticVariable':
      return 'Static';
    case 'ReportTemplateChoiceVariable':
      return 'Choice';
    case 'ReportTemplateWallMotionVariable':
      return 'Wall Motion';
    case 'ReportTemplateMediaAttachmentVariable':
      return 'Media';
    default:
      throw new Error('Unable to render report template variable type, type not supported');
  }
};

export const renderVariableControlType = (type: VariableControlType): string => {
  switch (type) {
    case VariableControlType.Input:
      return 'Input';
    case VariableControlType.Datepicker:
      return 'Datepicker';
    case VariableControlType.Select:
      return 'Select';
    case VariableControlType.Multiselect:
      return 'Multiselect';
    case VariableControlType.Radio:
      return 'Radio';
    case VariableControlType.Checkbox:
      return 'Checkbox';
  }
};

export const getReportVariableById = <A extends ReportVariable>(
  variables: A[],
  id: string,
): A | null => {
  return variables.find((variable) => variable.id === id) ?? null;
};

export const getReportVariableByAlias = <A extends ReportVariable>(
  variables: A[],
  alias: string,
): A | null => {
  return variables.find((variable) => variable.alias === alias) ?? null;
};

export const getReportVariable = <A extends ReportVariable>(
  variables: A[],
  idOrAlias: string,
): A | null => {
  return (
    getReportVariableById(variables, idOrAlias) ?? getReportVariableByAlias(variables, idOrAlias)
  );
};

export const getReportVariableValue = <A = unknown, B extends ReportVariable = ReportVariable>(
  variables: B[],
  idOrAlias: string,
): A | null => {
  return getReportVariable(variables, idOrAlias)?.value ?? null;
};

export const getReportVariableValueAsString = <A extends ReportVariable>(
  variables: A[],
  idOrAlias: string,
): string | null => {
  const value = getReportVariableValue(variables, idOrAlias);

  if (value) {
    return value.toString();
  }

  return null;
};

export const getReportVariableValueAsNumber = <A extends ReportVariable>(
  variables: A[],
  idOrAlias: string,
): number | null => {
  const value = getReportVariableValue(variables, idOrAlias);

  if (P.isNil(value)) {
    return null;
  }

  return P.parseNumeric(value) ?? null;
};

export const isReportStaticVariable = (
  variable: ReportVariable,
): variable is ReportStaticVariable => {
  return variable.__typename === 'ReportStaticVariable';
};

export const isReportChoiceVariable = (
  variable: ReportVariable,
): variable is ReportChoiceVariable => {
  return variable.__typename === 'ReportChoiceVariable';
};

export const isReportWallMotionVariable = (
  variable: ReportVariable,
): variable is ReportWallMotionVariable => {
  return variable.__typename === 'ReportWallMotionVariable';
};

export const isReportMediaAttachmentVariable = (
  variable: ReportVariable,
): variable is ReportMediaAttachmentVariable => {
  return variable.__typename === 'ReportMediaAttachmentVariable';
};

export const isReportTemplateStaticVariable = (
  variable: ReportTemplateVariable,
): variable is ReportTemplateStaticVariable => {
  return variable.__typename === 'ReportTemplateStaticVariable';
};

export const isReportTemplateChoiceVariable = (
  variable: ReportTemplateVariable,
): variable is ReportTemplateChoiceVariable => {
  return variable.__typename === 'ReportTemplateChoiceVariable';
};

export const isReportTemplateWallMotionVariable = (
  variable: ReportTemplateVariable,
): variable is ReportTemplateWallMotionVariable => {
  return variable.__typename === 'ReportTemplateWallMotionVariable';
};

export const isReportTemplateMediaAttachmentVariable = (
  variable: ReportTemplateVariable,
): variable is ReportTemplateMediaAttachmentVariable => {
  return variable.__typename === 'ReportTemplateMediaAttachmentVariable';
};

export const getReportVariableValueAsDateTime = <A extends ReportVariable>(
  variables: A[],
  idOrAlias: string,
): DateTime | null => {
  const value = getReportVariableValue<string>(variables, idOrAlias);

  if (!value) {
    return null;
  }

  const dateTime = DateTime.fromISO(value);

  if (dateTime.isValid) {
    return dateTime;
  }

  return null;
};

export const computeStatementFromTemplate = (
  timezone: string,
  template: ReportTemplateStatement,
  reportTemplate: ReportTemplate,
  variables: ReportVariable[],
): ReportStatement => {
  const value = compileHandlebarsTemplate({
    timezone,
    template: template.value,
    reportTemplate,
    values: P.keyBy(variables ?? [], (v) => v.id),
  });

  return {
    id: template.id,
    value,
  };
};

export const getDefaultStatementSites = (
  statementSites: ReportTemplateStatementSite[],
): ReportStatementSite[] => {
  return statementSites.map((reportTemplateStatementSite) => {
    const statements = reportTemplateStatementSite.statements
      .filter((s) => s.default)
      .map((s) => convertHandlebarsTemplateToTiptap(s.value));

    const value: string = P.run(() => {
      if (
        reportTemplateStatementSite.name === 'Conclusions' ||
        reportTemplateStatementSite.name === 'Recommendations'
      ) {
        const items = statements.map((s) => `<li>${s}</li>`);

        return `<ol>${items.join('')}</ol>`;
      } else {
        return statements.join(' ');
      }
    });

    return {
      id: reportTemplateStatementSite.id,
      name: reportTemplateStatementSite.name,
      statements: [
        {
          id: uuid(),
          value,
        },
      ],
    };
  });
};

export const getDefaultVariableValue = (reportTemplateVariable: ReportTemplateVariable): any => {
  switch (reportTemplateVariable.__typename) {
    case 'ReportTemplateStaticVariable':
    case 'ReportTemplateMediaAttachmentVariable':
      return reportTemplateVariable.defaultValue;
    case 'ReportTemplateChoiceVariable':
      return reportTemplateVariable.choices.find((choice) => choice.default)?.value;
    case 'ReportTemplateWallMotionVariable': {
      const module = getWallMotionModule(reportTemplateVariable.defaultValue);
      return renderWallMotion(
        module.present.yes.value,
        reportTemplateVariable.defaultValue.wmComplex ?? {},
        module,
      );
    }
  }
};

export const formValuesFromReport = (
  statementSites: ReportStatementSite[],
  variables: ReportVariable[],
): ReportFormValues => {
  return {
    statementSites: P.keyBy(statementSites ?? [], (ss) => ss.id),
    variables: P.keyBy(variables ?? [], (v) => v.id),
  };
};

export const formValuesFromReportTemplate = (
  reportTemplateStatementSites: ReportTemplateStatementSite[],
  reportTemplateVariables: ReportTemplateVariable[],
  reportVariables: ReportVariable[],
): ReportFormValues => {
  // If report variables are supplied, use them (i.e. when a dry run is used). Otherwise
  // try to fake report variables using the report template variable default values
  const variables = P.run(() => {
    if (!P.isEmpty(reportVariables)) {
      return reportVariables;
    }

    return reportTemplateVariables.map((reportTemplateVariable) => {
      return {
        id: reportTemplateVariable.id,
        value: getDefaultVariableValue(reportTemplateVariable),
      };
    }) as unknown as ReportVariable[];
  });

  const statementSites = getDefaultStatementSites(reportTemplateStatementSites);

  return {
    statementSites: P.keyBy(statementSites ?? [], (ss) => ss.id),
    variables: P.keyBy(variables ?? [], (v) => v.id),
  };
};

export const serializeExportedReportTemplate = (
  reportTemplate: ReportTemplate,
): ExportedReportTemplate => {
  return {
    id: reportTemplate.id,
    name: reportTemplate.name,
    statementSites: reportTemplate.statementSites,
    variables: reportTemplate.variables,
    reportNodes: reportTemplate.reportNodes,
  };
};

export const deserializeExportedReportTemplate = (reportTemplate: ExportedReportTemplate) => {
  return {
    name: reportTemplate.name,
    statementSites: reportTemplate.statementSites,
    variables: reportTemplate.variables,
    reportNodes: reportTemplate.reportNodes,
  };
};

export const getReportTemplateVariableById = <A extends ReportTemplateVariable>(
  variables: A[],
  variableId: string,
): A | null => {
  return variables.find((vt) => vt.id === variableId) ?? null;
};

export const getReportTemplateVariableByAlias = <A extends ReportTemplateVariable>(
  variables: A[],
  alias: string,
): A | null => {
  return variables.find((vt) => vt.alias === alias) ?? null;
};

export const getReportTemplateVariable = <A extends ReportTemplateVariable>(
  variables: A[],
  idOrAlias: string,
): A | null => {
  return (
    getReportTemplateVariableById(variables, idOrAlias) ??
    getReportTemplateVariableByAlias(variables, idOrAlias)
  );
};

export const extractVariableIdsFromStatement = (statement: string): string[] => {
  const regex = /<variable id="(.*?)"/g;
  const matches = [];

  let match: RegExpExecArray | null = regex.exec(statement);

  while (match !== null) {
    matches.push(match[1]);
    match = regex.exec(statement);
  }

  return matches;
};
